import usePrevious from 'hooks/usePrevious';
import { useState, useEffect, useRef } from 'react'
import useNotificationStore from 'store/notificationStore';

export default function useFetch(url) {
  const [isLoading, setIsLoading] = useState(url != null);
  const [isReloading, setIsReloading] = useState(url != null);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const [reloadCounter, setReloadCounter] = useState(0);
  const prevUrl = usePrevious(url);
  const addMessage = useNotificationStore(s => s.addMessage);

  const reload = () => {
    setReloadCounter((s) => s + 1);
    console.log('reload:', reloadCounter);
  }

  async function fetchData(controller, options = {}) {
    if (url) {
      if (reloadCounter > 0) {
        setIsReloading(true);
      } else {
        setIsLoading(true);
      }
      if (options.reset) {
        setData(null);
      }
      setStatus(null);
      setIsNotFound(false);
      setIsError(false);

      try {
        const startTime = performance.now();

        const response = await fetch(url, {
          credentials: 'include',
          signal: controller.signal,
        });

        const endTime = performance.now();
        const loadingTime = endTime - startTime;
        
        // console.log('Fetch:', Math.round(loadingTime), 'ms');
        if (loadingTime > 1000) {
          console.log('Slow fetch:', Math.round(loadingTime), 'ms', { url: '/' + url.split('/').slice(3).join('/') });
        }


        setStatus(response.status);

        if (response.status == 200) {
          const responseJson = await response.json();
          setData(responseJson);
          setIsLoading(false);
          setIsReloading(false);
        } else if (response.status == 404) {
          setIsNotFound(true);
          setIsLoading(false);
          setIsReloading(false);
        } else {
          addMessage('Error loading data from server', 'error')
          setIsError(true);
          setIsLoading(false);
          setIsReloading(false);
        }
        
      } catch (e) {
        if (e.name != 'AbortError') {
          addMessage('Error loading data from server', 'error')
          console.error(e);
          setIsError(true);
        }
      }
    } else {
      controller.abort();

      setIsLoading(false);
      setIsReloading(false);
      setData(null);
      setStatus(null);
      setIsNotFound(false);
      setIsError(false);
    }
  }

  useEffect(() => {
    let controller = new AbortController();

    const urlChanged = (prevUrl != url);

    fetchData(controller, { reset: urlChanged });

    return () => {
      controller.abort();
    }

  }, [url, reloadCounter]);

  return { data, status, isLoading, isReloading, isNotFound, isError, reload };
}
