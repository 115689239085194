import { Tooltip } from 'antd';
import LastValueCascader from 'components/Form/LastValueCascader';
import useFetchEntities from 'hooks/useFetchEntities';
import React from 'react'
import { formatDateToShortFormat, formatTimeStringToHHMM } from 'utils/IGdateTimeFunctions';
import { sortDateAndTime } from 'utils/sorters';

function GameSelector({ value, onChange }) {

    const { data: competitionData } = useFetchEntities('Competition', {
        relations: [
            'Seasons(name)',
            'Seasons>Groups(name)',
            'Seasons>Groups>Phases(name)',
            'Seasons>Groups>Phases>Games(startDate,startTime)',
            'Seasons>Groups>Phases>Games>Venue(name)',
            'Seasons>Groups>Phases>Games>HomeTeam(short)',
            'Seasons>Groups>Phases>Games>AwayTeam(short)',
        ].join(','),
    });

    const membershipOptions = competitionData
        ?.map(c => ({
            value: c.competitionId,
            label: c.Seasons.length > 0 ? c.name : <Tooltip title="There are no Seasons in this Competition">{c.name}</Tooltip>,
            disabled: c.Seasons.length == 0,
            children: c.Seasons.map(s => ({
                value: s.seasonId,
                label: s.Groups.length > 0 ? s.name : <Tooltip title="There are no Groups in this Season">{s.name}</Tooltip>,
                disabled: s.Groups.length == 0,
                children: s.Groups.map(g => ({
                    value: g.groupId,
                    label: g.Phases.length > 0 ? g.name : <Tooltip title="There are no Phases in this Group">{g.name}</Tooltip>,
                    disabled: g.Phases.length == 0,
                    children: g.Phases.map(p => ({
                        value: p.phaseId,
                        label: p.name,
                        disabled: p.Games.length == 0,
                        children: p.Games
                            .sort(
                                (g1, g2) => sortDateAndTime(g1.startDate, g1.startTime, g2.startDate, g2.startTime)
                            )
                                .map(gm => ({
                                value: gm.gameId,
                                label: `${formatDateToShortFormat(gm.startDate)} ${formatTimeStringToHHMM(gm.startTime)} - ${gm.Venue?.name} - ${gm.HomeTeam?.short} vs ${gm.AwayTeam?.short}`,
                            }))
                    }))
                }))
            }))
        }));

    return (
        <LastValueCascader
            options={membershipOptions}
            className="w-full"
            value={value}
            onChange={onChange ? (value) => onChange(value) : null}
        />
    )
}

export default GameSelector