import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';
import { Button, Modal, Tooltip } from 'antd';
import moment from 'moment';
import { TableOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import GamesTimelineModal from 'components/Games/GamesTimelineModal';

const Games = () => {
  const entities = useEntities();
  const [gamesTimelineData, setGamesTimelineData] = useState(null);

  const filterGroups = [
    [
      { label: 'All', preferredSort: { column: 'StartDateTime-nowrap', direction: 'descend' }, filters: { status: undefined } },
      { label: 'Past', preferredSort: { column: 'StartDateTime-nowrap', direction: 'descend' }, filters: { startDate: '<' + moment().format('YYYY-MM-DD') } },
      { label: 'Last 7 days', preferredSort: { column: 'StartDateTime-nowrap', direction: 'descend' }, filters: { startDate: ['>' + moment().subtract(7, 'days').format('YYYY-MM-DD'), '<' + moment().format('YYYY-MM-DD')] } },
      { label: 'Yesterday', preferredSort: { column: 'StartDateTime-nowrap', direction: 'descend' }, filters: { startDate: moment().subtract(1, 'day').format('YYYY-MM-DD') } },
      { label: 'Today', preferredSort: { column: 'StartDateTime-nowrap', direction: 'descend' }, filters: { startDate: moment().format('YYYY-MM-DD') }, isDefault: true },
      { label: 'Next 7 days', preferredSort: { column: 'StartDateTime-nowrap', direction: 'ascend' }, filters: { startDate: ['>' + moment().subtract(0, 'days').format('YYYY-MM-DD'), '<' + moment().add(8, 'days').format('YYYY-MM-DD')] } },
      { label: 'Future', preferredSort: { column: 'StartDateTime-nowrap', direction: 'ascend' }, filters: { startDate: '>' + moment().format('YYYY-MM-DD') } },
    ],
  ];

  const showTimeline = (filteredData) => {
    setGamesTimelineData(filteredData);
  }

  return (
    <>
      <GamesTimelineModal
        open={gamesTimelineData}
        onClose={() => { setGamesTimelineData(null) }}
        games={gamesTimelineData}
      />

      <HmsEntityTableController
        entity={entities.Game}
        filterGroups={filterGroups}
        extraButtonBarItems={({ filteredData }) => {
          return (
            <Button
              size="small"
              icon={<TableOutlined />}
              onClick={() => showTimeline(filteredData)}
            >
              Timeline
            </Button>
          );
        }}
        columnSets={[
          {
            name: 'Admin',
            columns: [
              { column: 'StartDateTime-nowrap', width: 200, defaultSortOrder: 'descend' },
              { column: 'Venue', width: 200, },
              { column: 'HomeTeam-nick', width: 150, },
              { column: 'Score', width: 80, },
              { column: 'AwayTeam-nick', width: 150, },
              { column: 'status', width: 120, },
              { column: 'timekeeper', width: 150, },
              { column: 'referee', width: 150, },
              { column: 'PaymentHost', width: 150, },
              { column: 'PaymentGuest', width: 150, },
              { column: 'PaymentReferree', width: 150, },
              { column: 'PaymentTimekeeper', width: 150, },
              { column: 'HostExternalId', width: 150, },
              { column: 'GuestExternalId', width: 150, },
              { column: 'foreignId', width: 150, },
              { column: 'Phase', width: 150, },
              { column: 'Group', width: 150, },
              { column: 'Season', width: 150, },
              { column: 'Competition', width: 150, },
              { column: 'name', width: 150 },
              { column: 'Info', width: 150 },
              { column: 'Description', width: 150 },
              { column: 'StartDateTime-dateonly', visible: false, exported: true, filterDisabled: true },
              { column: 'StartDateTime-weekday', visible: false, exported: true, filterDisabled: true },
              { column: 'StartDateTime-timeonly', visible: false, exported: true, filterDisabled: true },
            ],
          },
          {
            name: 'Reporter',
            columns: [
              { column: 'StartDateTime-nowrap', width: 200, defaultSortOrder: 'descend' },
              { column: 'Venue', width: 200, },
              { column: 'HomeTeam-nick', width: 150, },
              { column: 'Score', width: 80, },
              { column: 'AwayTeam-nick', width: 150, },
              { column: 'HomeTeamStar', width: 150, },
              { column: 'AwayTeamStar', width: 150, },
              { column: 'HomeTeamGoalie', width: 150, },
              { column: 'AwayTeamGoalie', width: 150, },
              { column: 'timekeeper', width: 150, },
              { column: 'referee', width: 150, },
              { column: 'Phase', width: 150, },
              { column: 'Group', width: 150, },
              { column: 'Season', width: 150, },
              { column: 'Competition', width: 150, },
              { column: 'Headline', width: 150, },
              { column: 'Perex', width: 150, },
              { column: 'Body', width: 150, },
              { column: 'FlickrAlbumWithValidation', width: 330, },
              { column: 'youtubeVideoUrl', width: 250, },
              { column: 'StartDateTime-dateonly', visible: false, exported: true, filterDisabled: true },
              { column: 'StartDateTime-weekday', visible: false, exported: true, filterDisabled: true },
              { column: 'StartDateTime-timeonly', visible: false, exported: true, filterDisabled: true },
            ],
          },
          {
            name: 'Statistics',
            columns: [
              { column: 'StartDateTime-nowrap', width: 200, defaultSortOrder: 'descend' },
              { column: 'Venue', width: 200, },
              { column: 'HomeTeam-nick', width: 150, },
              { column: 'Score', width: 80, },
              { column: 'AwayTeam-nick', width: 150, },
              { column: 'HomeTeamStar', width: 150, },
              { column: 'AwayTeamStar', width: 150, },
              { column: 'HomeTeamGoalie', width: 150, },
              { column: 'AwayTeamGoalie', width: 150, },
              { column: 'timekeeper', width: 150, },
              { column: 'referee', width: 150, },
              { column: 'Phase', width: 150, },
              { column: 'Group', width: 150, },
              { column: 'Season', width: 150, },
              { column: 'Competition', width: 150, },
              { column: 'HomeTeamGoals' },
              { column: 'AwayTeamGoals' },
              { column: 'HomeTeamPenalties' },
              { column: 'AwayTeamPenalties' },
              { column: 'HomeTeamSaves' },
              { column: 'AwayTeamSaves' },
              { column: 'HomeTeamFaceOffs' },
              { column: 'AwayTeamFaceOffs' },
              { column: 'HomeTeamName', width: 200, },
              { column: 'AwayTeamName', width: 200, },
              { column: 'name', width: 150 },
              { column: 'StartDateTime-dateonly', visible: false, exported: true, filterDisabled: true },
              { column: 'StartDateTime-weekday', visible: false, exported: true, filterDisabled: true },
              { column: 'StartDateTime-timeonly', visible: false, exported: true, filterDisabled: true },
            ],
          },
        ]}
      />
    </>
  )
}

export default Games