import React from "react";
import BaseTableRows from "./BaseTableRows";

const TableStandings = ({ data }) => (
  <table className="text-center italic">
    <thead>
      <tr className="border-b font-bold">
        <th colSpan="3" />
        <th className="px-4">Z</th>
        <th className="bg-green-600 px-4">V</th>
        <th className="bg-gray-500 px-4">R</th>
        <th className="bg-red-500 px-4">P</th>
        <th className="px-6">:</th>
        <th className="border-x px-6">B</th>
        <th className="px-4 text-sm text-red-200">TM</th>
      </tr>
    </thead>
    <tbody>
      {data.map((team, index) => (
        <tr
          key={index}
          className={index === 7 ? "border-b border-red-200" : undefined}
        >
          <BaseTableRows
            index={index}
            icon={team.Logo}
            name={team.Name}
            nameClassName={
              index === 0
                ? "!font-bold"
                : index === 7
                  ? "border-none"
                  : undefined
            }
          />
          <td className="">{team.Z}</td>
          <td className="text-green-600">{team.V}</td>
          <td className="text-gray-500">{team.R}</td>
          <td className="text-red-500">{team.P}</td>
          <td className="text-sm">{team.Score}</td>
          <td className="border-x text-lg">{team.B}</td>
          <td className="text-sm text-red-200">{team.TM}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default TableStandings;
