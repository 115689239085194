import { Link } from 'react-router-dom';
import DisplayColor from 'components/DisplayColor';
import HmsStatusTag from 'components/HmsStatusTag';
import dayjs from 'dayjs';
import { sortDate, sortDateAndTime, sortString, sortTime } from 'utils/sorters';
import renderWithTooltipIfNeeded from 'utils/renderWithTooltipIfNeeded';
import icons from 'utils/icons';
import { Tooltip } from 'antd';

function generateEntityColumnInner(entity, f, { countries } = {}) {
  const c = {
    title: f.displayName, 
    dataIndex: f.name, 
    searchValue: (record) => record[f.name] ?? '',
    width: f.width,
    requiredAttributes: [f.name],
  };
  switch (f.name) {
    case 'email':
      c.width = 250;
      break;
    case 'phone':
      c.width = 150;
      break;
    case 'country':
    case 'countryBirth':
      c.width = 200;
      break;
    case 'city':
    case 'cityBirth':
      c.width = 200;
      break;
    case 'foundDate':
      c.width = 110;
      break;
  }
  const coreType = f.type.split('(')[0];
  switch (coreType) {
    case 'COLOR':
      c.render = (text, record) => <DisplayColor name={record.name} hex={record.hex} />
      break;
    case 'DATE':
      c.render = (text, record) => <span className="whitespace-nowrap">{record[f.name] ? dayjs(record[f.name]).format('DD.MM.YYYY HH:mm:ss') : ''}</span>
      c.searchValue = (record) => record[f.name] ? dayjs(record[f.name]).format('DD.MM.YYYY HH:mm:ss') : '';
      c.sortDirections = ['ascend', 'descend'];
      c.sorter = (a, b) => sortDate(a[f.name], b[f.name]);
      c.filterType = 'datetime';
      break;
    case 'TIME':
      c.render = (text, record) => <span className="whitespace-nowrap">{formatTimeShort(record[f.name])}</span>
      c.searchValue = (record) => formatTimeShort(record[f.name]);
      c.filterType = 'time';
      break;
    case 'DATEONLY':
      c.render = (text, record) => <span className="whitespace-nowrap">{record[f.name] ? dayjs(record[f.name]).format('DD.MM.YYYY') : ''}</span>
      c.sortDirections = ['ascend', 'descend'];
      c.sorter = (a, b) => sortDate(a[f.name], b[f.name]);
      c.searchValue = (record) => record[f.name] ? dayjs(record[f.name]).format('DD.MM.YYYY') : '';
      c.filterType = 'date';
      break;
    case 'STRING':
    case 'NVARCHAR':
    case 'LONG_STRING':
    case 'ENUM':
      c.sorter = (a, b) => sortString(a[f.name], b[f.name]);
      c.sortDirections = ['ascend'];
      switch (f.name) {
        case 'status':
          c.render = (text, record) => <HmsStatusTag status={text} entity={entity} />
          c.exportValue = (r) => r.status;
          break;
        case 'description':
          c.render = (text, record) => renderWithTooltipIfNeeded(text);
          break;
        case 'email':
          break;
        case 'phone':
          c.exportExactCsv = true;
          break;
        case 'country':
        case 'countryBirth':
          c.render = (text, record) => <span className="whitespace-nowrap">{countries?.find(c => c.value == text)?.label ?? text}</span>;
          break;
      }
      if (coreType == 'ENUM') {
        c.filterType = 'list';
        c.render = (text, record) => f.options?.find(o => o.value == text)?.label ?? text;
      }
      break;
    case 'COLOR_LIST':
      c.align = 'right';
      break;
    case 'INTEGER':
    case 'DOUBLE':
      c.align = 'right';
      c.filterType = 'number';
      break;
    case 'BOOLEAN':
      c.align = 'right';
      break;
    case 'UNIQUEIDENTIFIER':
      break;
    case 'RELATION':
      break;
    default:
      console.log('Unknown field type:', coreType);
  }

  if (f.render) {
    c.render = f.render;
  }

  return c;

}

export function generateEntityColumns(entity, fields, { countries } = {}) {
  if (entity == null) {
    console.warn('generateEntityColumns received entity = null');
  }
  if (fields == null) {
    throw 'generateEntityColumns received fields = null';
  }

  const result = {};

  Object.values(fields)
  .filter(f => !f.primaryKey && f.name !== 'logo' && f.name != 'avatarUrl' && f.name !== 'logoUrl' && f.name !== 'logoUrl' && f.name !== 'logoMetadata')
  .concat([
    {
      name: 'createdAt',
      displayName: 'Created At',
      type: 'DATE',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => sortDate(a.createdAt, a.createdAt),
      width: 180,
    },
    {
      name: 'createdBy',
      displayName: 'Created By',
      type: 'STRING',
      width: 200,
      render: (text) => {
        const regex = /([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})\s+\(([A-F0-9-]+)\)(\s+\((CLONE)\))?/;
        const match = text.match(regex);

        if (match) {
          const email = match[1];
          const userId = match[2];
          const isClone = match[4] === 'CLONE';

          if (!isClone) {
            return email;
          } else {
            return <span className="flex items-center">
              <span>{email}</span>
              <Tooltip title="Created as a Clone">
                <span className="text-gray-400 ml-2">
                  {icons.Clone}
                </span>
              </Tooltip>
            </span>
          }

        } else {
          return text;
        }
      }
    }
  ])
  .forEach((f) => {
    result[f.name] = generateEntityColumnInner(entity, f, { countries });
  });

  return result;
}

export function formatDate(v, options = {}) {
  if (!v) {
    return '';
  }
  if (options.withWeekDay) {
    return dayjs(v).format('ddd DD.MM.YYYY');
  }
  return dayjs(v).format('DD.MM.YYYY');
};

export function formatWeekday(v) {
  if (!v) {
    return '';
  }
  return dayjs(v).format('ddd');
}

export function formatTime(v, options = {}) {
  if (v && v.length == 8) {  // HH:mm:ss
    return v.substring(0, 5);  // HH:mm
  }
  return v ? dayjs(v).format(options?.seconds ? 'HH:mm:ss' : 'HH:mm') : '';
};

export function formatDateTime(v) {
  if (!v) {
    return '';
  }
  return dayjs(v).format('DD.MM.YYYY HH:mm:ss');
}

export function formatTimeShort(s) {
  return s?.split(':')?.slice(0, 2)?.join(':');
}

export function formatCreatedBy (createdBy) {
    return createdBy ? (createdBy.indexOf('(') != -1 ? createdBy.split('(')[0].trim() : createdBy) : '';
}

