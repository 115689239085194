import { Cascader, Select, Tooltip } from 'antd';
import LastValueCascader from 'components/Form/LastValueCascader';
import useFetchEntities from 'hooks/useFetchEntities';
import { last } from 'lodash';
import React, { useEffect } from 'react'

function PhaseSelector({ value, onChange, mandatory = [] }) {
    const { data: competitionData } = useFetchEntities('Competition', { relations: 'Seasons>Groups>Phases' });

    const [competitionId, setCompetitionId] = React.useState(value?.competitionId);
    const [seasonId, setSeasonId] = React.useState(value?.seasonId);
    const [groupId, setGroupId] = React.useState(value?.groupId);
    const [phaseId, setPhaseId] = React.useState(value?.phaseId);

    const handleCompetitionChange = (value) => {
        setCompetitionId(value);
        setSeasonId(null);
        setGroupId(null);
        setPhaseId(null);
    }

    const handleSeasonChange = (value) => {
        setSeasonId(value);
        setGroupId(null);
        setPhaseId(null);
    }

    const handleGroupChange = (value) => {
        setGroupId(value);
        setPhaseId(null);
    }

    const handlePhaseChange = (value) => {
        setPhaseId(value);
    }

    useEffect(() => {
        if (competitionData) {
            fireOnChange();
        }
    }, [competitionId, seasonId, groupId, phaseId]);

    const fireOnChange = () => {
        onChange({
            competitionId,
            seasonId,
            groupId,
            phaseId,
        });
    }

    const Placeholder = ({ text, mandatory }) => (
        <span>
            <span>{text}</span>
            {mandatory && <span className="text-red-500"> *</span>}
        </span>
    )

    return (
        <div className="grid grid-cols-4 gap-2">
            <Select
                options={competitionData?.map(c => ({
                    label: c.name,
                    value: c.competitionId,
                }))}
                value={competitionId}
                onChange={handleCompetitionChange}
                placeholder={<Placeholder text="Competition" mandatory={mandatory.includes('competitionId')} />}
                allowClear
            />

            <Select
                options={competitionData
                    ?.find(c => c.competitionId == competitionId)
                    ?.Seasons
                    ?.map(s => ({
                        label: s.name,
                        value: s.seasonId,
                    }))
                }
                value={seasonId}
                onChange={handleSeasonChange}
                placeholder={<Placeholder text="Season" mandatory={mandatory.includes('seasonId')} />}
                allowClear
            />

            <Select
                options={competitionData
                    ?.find(c => c.competitionId == competitionId)
                    ?.Seasons
                    ?.find(s => s.seasonId == seasonId)
                    ?.Groups
                    ?.map(g => ({
                        label: g.name,
                        value: g.groupId,
                    }))
                }
                value={groupId}
                onChange={handleGroupChange}
                placeholder={<Placeholder text="Group" mandatory={mandatory.includes('groupId')} />}
                allowClear
            />

            <Select
                options={competitionData
                    ?.find(c => c.competitionId == competitionId)
                    ?.Seasons
                    ?.find(s => s.seasonId == seasonId)
                    ?.Groups
                    ?.find(g => g.groupId == groupId)
                    ?.Phases
                    ?.map(p => ({
                        label: p.name,
                        value: p.phaseId,
                    }))
                }
                value={phaseId}
                onChange={handlePhaseChange}
                placeholder={<Placeholder text="Phase" mandatory={mandatory.includes('phaseId')} />}
                allowClear
            />
        </div>
    )
}

export default PhaseSelector