import React, { useEffect, useState } from 'react'
import EmbedCard from 'components/Embeds/EmbedCard';
import EmbedCardVideo from 'components/Embeds/EmbedCardVideo';
import EmbedCardTitleDivier from 'components/Embeds/EmbedCardTitleDivier';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import { Link } from 'react-router-dom';

const EmbedDisciplinaryInner = ({ data, lang }) => {
  return (
    <div className="min-h-screen bg-[#eeeeee]">
      <div className="flex flex-col gap-5 items-center dekstop:mx-10 p-5 dekstop:p-10">

        <EmbedCard>
          <div className="text-xl">
            Disciplinární řízení
          </div>

          <EmbedCardTitleDivier />

          <div className="mt-4 grid grid-cols-[1fr_6fr] gap-y-4">
            <span className="text-[#696969] font-light whitespace-pre-line">Liga:</span>
            <span>{data?.Competition?.name}</span>

            <span className="text-[#696969] font-light whitespace-pre-line">Zápas:</span>
            {data.Game ? (
              <Link to={`/embed/game?gameId=${data.Game?.gameId}&lang=${lang}`} target='_blank' className="text-black hover:no-underline">
                {data.Game?.startDate ? formatDate(data.Game?.startDate) : 'bez data'} {formatTime(data.Game?.startTime)} - {data.Game?.HomeTeam?.short} vs {data.Game?.HomeTeam?.short} ({data.Game?.Venue?.name ?? 'bez místa'})
              </Link>
            ) : (
              'bez zápasu'
            )}

            <span className="text-[#696969] font-light whitespace-pre-line">Hráč:</span>
            <span>
              {data.Players?.map((p, idx) => (
                  <span key={idx}>
                    {p.firstName} {p.lastName}
                    {idx != data.Players.length - 1 ? ', ' : ''}
                  </span>
              ))}
            </span>

            <span className="text-[#696969] font-light whitespace-pre-line">Předmět:</span>
            <span>{data.subject}</span>

            <span className="text-[#696969] font-light whitespace-pre-line">Stav:</span>
            <span>{data.status}</span>

            <span className="text-[#696969] font-light whitespace-pre-line">Datum:</span>
            <span>{formatDate(data.date)}</span>
          </div>

          <div className="mt-4">
            <EmbedCardTitleDivier />
          </div>

          <div className="mt-4 grid grid-cols-[1fr_6fr] gap-y-4">
            <span className="text-[#696969] font-light whitespace-pre-line">Popis:</span>
            <span className="font-light whitespace-pre-line">{(data.description && data.description != '') ? data.description : '-'}</span>

            <span className="text-[#696969] font-light whitespace-pre-line">Výsledek:</span>
            <span className="font-light whitespace-pre-line">{(data.result && data.result != '') ? data.result : '-'}</span>

          </div>

        </EmbedCard>

        {data.Attachments?.length > 0 &&
          <EmbedCard>
            <div className="text-xl">
              Přílohy
            </div>

            <EmbedCardTitleDivier />

            {data.Attachments?.map((att, idx) => (
              <a href={att.publicUrl} target="_blank" className="text-black hover:no-underline">
                <div className="font-light">{att.fileName}</div>
              </a>
            ))}
          </EmbedCard>
        }

        <EmbedCardVideo youtubeVideoUrl={data.youtubeUrl} />

      </div>
    </div>
  );
}

export default EmbedDisciplinaryInner