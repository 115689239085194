import React, { useState } from 'react'
import { getDefaultCardWidth } from 'utils/uiPreferences';
import icons from 'utils/icons';
import { Button } from 'antd';
import Confirm from 'components/Confirm';
import useLoginStore from 'store/loginStore';

const ApprovalBar = ({ status, entity, isDirty, id, reload }) => {
  const userRoles = useLoginStore(s => s.roles);
  const isAdmin = userRoles?.indexOf('ADMIN') != -1;
  const [confirmOpen, setConfirmOpen] = useState(null);

  return (
    <>
      <Confirm
        message={confirmOpen?.message}
        confirmButtonText={confirmOpen?.confirmButtonText}
        confirmButtonLoading={confirmOpen?.confirmButtonLoading}
        confirmButtonType={confirmOpen?.confirmButtonType}
        confirmButtonProps={confirmOpen?.confirmButtonProps}
        cancelButtonProps={confirmOpen?.cancelButtonProps}
        visible={confirmOpen}
        handleConfirm={confirmOpen?.onOk}
        handleCancel={confirmOpen?.onCancel}
        title={confirmOpen?.title}
      />

      {!isAdmin && status == 'DRAFT' &&
        <div className={`bg-[#ddeeff] text-[#2280ff] rounded-md mb-3 p-3 ${getDefaultCardWidth(true)} flex items-center justify-between border-[1px] border-[#cee7ff]`}>
          <div className="w-full flex items-center gap-3">
            <span className="text-[#2280ff]">{icons.Information}</span>
            <span>This {entity.displayName} is not approved yet. {isDirty ? 'Please save it first and send it for approval.' : 'Once you are ready, please send it for approval.'}</span>
            <Button
              className="ml-auto"
              size="small"
              type="primary"
              disabled={isDirty}
              onClick={() => {
                setConfirmOpen({
                  title: 'Confirm',
                  message:
                    <div className="mt-4 mb-6">
                      By sending this record for approval:
                      <ul>
                        <li>All fields will be locked for editing</li>
                        <li>Organization administrator will be notified about approval request</li>
                      </ul>
                      Please confirm that you want to send this {entity.displayName} for approval?
                    </div>,
                  confirmButtonText: 'Yes - Confirm',
                  confirmButtonProps: { size: 'small' },
                  cancelButtonProps: { size: 'small' },
                  onOk: async () => {
                    await entity.patch(id, { status: 'PREAPPROVED' });
                    reload();
                    setConfirmOpen(null);
                  },
                  onCancel: () => {
                    setConfirmOpen(null);
                  }
                })
              }}
            >
              Send for approval
            </Button>
          </div>
        </div>
      }
      {!isAdmin && status == 'PREAPPROVED' &&
        <div className={`bg-[#ddeeff] text-[#2280ff] rounded-md mb-3 p-3 ${getDefaultCardWidth(true)} flex gap-2 items-center border-[1px] border-[#cee7ff]`}>
          <span className="text-[#2280ff]">{icons.Correct}</span>
          <span>This {entity.displayName} has been sent to the organization administrator and it's pending approval.</span>
        </div>
      }
    </>
  )
}

export default ApprovalBar