import { Button, Card, Dropdown, Tabs, Space } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Breadcrumbs from 'components/Breadcrumbs'
import IG10_GameStreak from 'components/Infographics/IG10_GameStreak'
import IG11_PointLeaders from 'components/Infographics/IG11_PointLeaders'
import IG1_GamesToday from 'components/Infographics/IG1_GamesToday'
import IG2_DayRecap from 'components/Infographics/IG2_DayRecap'
import IG3_WeekRecap from 'components/Infographics/IG3_WeekRecap'
import IG4_YoutubeThumbnail from 'components/Infographics/IG4_YoutubeThumbnail'
import IG5_StandingsByPoints from 'components/Infographics/IG5_StandingsByPoints'
import useSearchParamsMulti from 'hooks/useSearchParamsMulti'
import React, { createRef } from 'react'
import icons from 'utils/icons'
import { SettingOutlined, DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { domToPng } from 'modern-screenshot'
import useInterval from 'use-interval'

const Infographics = () => {
  const { useSearchParamSingle } = useSearchParamsMulti();
  const [selectedTab, setSelectedTab] = useSearchParamSingle('selectedTab', null);
  const [showData, setShowData] = React.useState(false);

  const items = [
    {
      key: 'IG1',
      label: '1. Games Today',
      component: IG1_GamesToday,
    },
    {
      key: 'IG2',
      label: '2. Day Recap',
      component: IG2_DayRecap,
    },
    {
      key: 'IG3',
      label: '3. Week Recap',
      component: IG3_WeekRecap,
    },
    {
      key: 'IG4',
      label: '4. Game YT',
      component: IG4_YoutubeThumbnail,
    },
    {
      key: 'IG5',
      label: '5. Standings By Points',
      component: IG5_StandingsByPoints,
    },
    {
      key: 'IG10',
      label: '10. Game Streak',
      component: IG10_GameStreak,
    },
    {
      key: 'IG11',
      label: '11. Point Leaders',
      component: IG11_PointLeaders,
    },
  ];

  const tabItems = items.map((item) => {
    const ref = createRef();  // generate unique ref for each item - otherwise exporting to PNG could fail
    return {
      key: item.key,
      label: item.label,
      ref,
      children: <item.component showData={showData} exportRef={ref} />,
    };
  });

  const downloadPngClicked = async () => {
    const ref = tabItems.find((item) => item.key === selectedTab)?.ref;
    const domObject = ref?.current;

    if (!domObject) {
      console.error('Failed to take screenshot (no DOM object)');
      return;
    }

    const options = {
      scale: 4,
      debug: true
    };
    domToPng(domObject, options).then(dataUrl => {
      if (dataUrl?.length < 100) {
        console.error('Failed to take screenshot (too small)');
        return;
      };
      const link = document.createElement('a')
      link.download = 'IG.png'
      link.href = dataUrl
      link.click()
    })
  }

  // test case for PNG export - might still need it...
  // useInterval(() => {
  //   downloadPngClicked();
  // }, 1000);

  return (
    <Content className="m-3 desktop:m-4 desktop:mr-0">
      <Breadcrumbs items={[{ title: 'Infographics', icon: icons.Infographics }]} />
      <div className="flex gap-5 items-start">
        <Card
          title={
            <div className="flex justify-between items-center">

              <span className="flex gap-4 items-center">
                Infographics generator
              </span>

              <span className="flex items-center gap-2">

                <Button
                  type="primary"
                  size="small"
                  icon={<DownloadOutlined />}
                  onClick={() => downloadPngClicked()}
                >
                  Download PNG
                </Button>

                <Dropdown
                  placement="bottomRight"
                  menu={{
                    items: [{
                      label: 'Show data',
                      onClick: () => setShowData(!showData),
                    }]
                  }}>
                  <Button size="small">
                    <Space>
                      <SettingOutlined />
                      System
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </span>
            </div>

          }
          className="w-full max-w-[calc(100vw-25px)] desktop:max-w-[calc(100vw-233px)]"
          size="small"
        >
          <div className="overflow-scroll">
            <Tabs defaultActiveKey="1"
              activeKey={selectedTab}
              onChange={(ak) => setSelectedTab(ak)}
              items={tabItems}
              tabPosition='left' />
            </div>
          </Card>
        </div>
      </Content>
  )
}

export default Infographics
