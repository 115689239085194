import React from 'react'
import { Button, DatePicker } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateFormatIG } from '../IGconfig';
dayjs.extend(customParseFormat);

const DateSelector = ({ value: date, onChange: setDate }) => {
  const prevDayClicked = () => setDate(dayjs(date, dateFormatIG).subtract(1, 'day').format(dateFormatIG));
  const nextDayClicked = () => setDate(dayjs(date, dateFormatIG).add(1, 'day').format(dateFormatIG));
  const todayClicked = () => setDate(dayjs(new Date()).format(dateFormatIG));

  return (
    <span className="flex items-center gap-2">
      <DatePicker
        value={dayjs(date, dateFormatIG)}
        format={dateFormatIG}
        onChange={(date, dateString) => { setDate(dateString) }}
        allowClear={false}
      />
      <Button onClick={prevDayClicked}><LeftOutlined /></Button>
      <Button onClick={nextDayClicked}><RightOutlined /></Button>
      <Button onClick={todayClicked}>Today</Button>
    </span>
  )
}

export default DateSelector