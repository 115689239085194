import React from 'react'
import {
    UserOutlined,
    AppstoreOutlined,
    ApartmentOutlined,
    DollarCircleOutlined,
    CalendarOutlined,
    BuildOutlined,
    CrownOutlined,
    TeamOutlined,
    SmileOutlined,
    ScheduleOutlined,
    ThunderboltOutlined,
    SaveOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    LeftOutlined,
    BranchesOutlined,
    ClusterOutlined,
    DeleteOutlined,
    AppstoreAddOutlined,
    LinkOutlined,
    FileExcelOutlined,
    FormOutlined,
    InfoCircleOutlined,
    CheckCircleOutlined,
    ForkOutlined,
    PieChartOutlined,
} from '@ant-design/icons';
import {
    FiExternalLink
} from 'react-icons/fi';
import {
    BsTable
} from 'react-icons/bs';
import {
    IoIosWarning
} from "react-icons/io";
import { BiTaskX } from "react-icons/bi";
import { FaTriangleExclamation } from "react-icons/fa6";
import { LiaClone } from "react-icons/lia";

export default {
    Dashboard: <AppstoreOutlined />,
    General: <AppstoreOutlined />,
    Competition: <ApartmentOutlined />,
    Sponsor: <DollarCircleOutlined />,
    Season: <CalendarOutlined />,
    Group: <ClusterOutlined />,
    Phase: <BranchesOutlined />,
    Venue: <BuildOutlined />,
    Award: <CrownOutlined />,
    Team: <TeamOutlined />,
    Player: <UserOutlined />,
    Person: <SmileOutlined />,
    Event: <ScheduleOutlined />,
    Game: <ThunderboltOutlined />,
    Run: <ThunderboltOutlined />,
    Disciplinary: <BiTaskX />,
    Save: <SaveOutlined />,
    Settings: <SettingOutlined />,
    Error: <ExclamationCircleOutlined />,
    ErrorTriangle: <FaTriangleExclamation />,
    Back: <LeftOutlined />,
    ExternalLink: <FiExternalLink />,
    Trash: <DeleteOutlined />,
    Create: <AppstoreAddOutlined />,
    Link: <LinkOutlined />,
    Export: <FileExcelOutlined />,
    Statistics: <BsTable size="12" />,
    WarningTriangle: <IoIosWarning />,
    SeasonRegistration: <FormOutlined />,
    Information: <InfoCircleOutlined />,
    Correct: <CheckCircleOutlined />,
    Scheduler: <ForkOutlined />,
    Infographics: <PieChartOutlined />,
    Clone: <LiaClone className="w-[1.3em] h-[1.3em]" />,
};
