import { useEffect, useState } from 'react';
import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import useUrlParams from 'hooks/useUrlParams';

const useLoadEntity = ({ entity, parentEntity, childEntity, relations, entityOptions = {} }) => {
  const { id, parentId } = useUrlParams();
  const { isLoading, isError, isNotFound, data, reload: reloadData, isReloading } = useFetchEntity(entity?.name, id, entity?.fetchRelations, entityOptions);
  const { isLoading: parentLoading, isError: parentIsError, isNotFound: parentNotFound, data: parentData, reload: reloadParent } = useFetchEntity(parentEntity?.name, parentId, parentEntity?.fetchRelations);  
  const { isLoading: chidrenLoading, isError: childrenIsError, data: childrenData, reload: reloadChildren } = useFetchEntities(childEntity?.name, { filters: { [entity?.primaryKey]: id } });
  const { isLoading: relationsLoading, isError: relationsIsError, data: relationsData, reload: reloadRelations } = useFetchEntity(entity?.name, relations ? id : null, relations, { onlyAttributes: [entity?.primaryKey] });

  const reload = () => {
    reloadData();
    reloadParent();
    reloadChildren();
    reloadRelations();
  }

  return {
    id, 
    parentId, 
    isLoading: isLoading || parentLoading || chidrenLoading || relationsLoading,
    isError: isError || parentIsError || childrenIsError || relationsIsError,
    isNotFound: isNotFound || parentNotFound, 
    data, 
    reloadData,
    parentData, 
    reloadParent,
    childrenData,
    reloadChildren,
    relationsData,
    reloadRelations,
    relationsLoading,
    reload,
    isReloading,
  };
}

export default useLoadEntity;
