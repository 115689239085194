import { Button, Checkbox, InputNumber, Radio } from 'antd';
import React, { useEffect, useState } from 'react'
import { DownOutlined, LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';

const SizeSelector = ({ value, onChange, defaultValue, presets }) => {
  const preset = value?.preset ?? defaultValue.preset;
  const width = value?.width ?? defaultValue.width;
  const height = value?.height ?? defaultValue.height;

  const defaultPresets = [
    {
      label: 'Landscape (16:9)',
      value: 'LANDSCAPE',
      aspect: 16 / 9,
    },
    {
      label: 'Portrait (9:16)',
      value: 'PORTRAIT',
      aspect: 9 / 16,
    },
    {
      label: 'Custom',
      value: 'CUSTOM',
      aspect: null,
    }
  ]

  const availablePresets = presets.map((option) => {
    if (typeof option === 'string') {
      return defaultPresets.find((item) => item.value === option);
    } else {
      return option;
    }
  });

  useEffect(() => {
    if (value == null) {
      onChange(defaultValue);
    }
  }, []);

  const reset = () => {
    onChange({
      preset: defaultValue.preset,
      width: defaultValue.width,
      height: defaultValue.height
    })
  }

  const changePreset = (newPreset) => {
    let newWidth;
    let newHeight;

    const selectedOption = availablePresets.find((item) => item.value === newPreset);

    if (selectedOption == null) {
      console.warn('Invalid option:', newPreset);
      return;
    }

    if (selectedOption.width != null && selectedOption.aspect != null) {
      newWidth = selectedOption.width;
      newHeight = Math.round(selectedOption.width / selectedOption.aspect);
    } else {
      const aspect = availablePresets.find((item) => item.value === newPreset)?.aspect;
      if (aspect != null) {
        if (newPreset >= 1) {
          newWidth = Math.max(width, height);
          newHeight = Math.round(newWidth / aspect);
        } else {
          newWidth = Math.min(width, height);
          newHeight = Math.round(newWidth / aspect);
        }
      } else {
        newWidth = width;
        newHeight = height;
      }
    }

    onChange({
      preset: newPreset,
      width: newWidth,
      height: newHeight
    });
  }

  const adjustWidth = (newWidth) => {
    let newHeight = height;

    const aspect = availablePresets.find((item) => item.value === preset)?.aspect;
    if (aspect != null) {
      newHeight = Math.round(newWidth / aspect);
    }

    onChange({
      preset: preset,
      width: newWidth,
      height: newHeight,
    });
  }

  const adjustHeight = (newHeight) => {
    let newWidth = width;

    const aspect = availablePresets.find((item) => item.value === preset)?.aspect;
    if (aspect != null) {
      newWidth = Math.round(newHeight * aspect);
    }

    onChange({
      preset: preset,
      width: newWidth,
      height: newHeight,
    });
  }

  const adjustSizeSmaller = () => {
    const percent = -1;
    const newWidth = Math.round(width * (100 + percent) / 100);

    const aspect = availablePresets.find((item) => item.value === preset)?.aspect;
    const newHeight = aspect != null ? Math.round(newWidth / aspect) : height;

    onChange({
      preset: preset,
      width: newWidth,
      height: newHeight,
    });
  }

  const adjustSizeLarger = () => {
    const percent = 1;
    const newWidth = Math.round(width * (100 + percent) / 100);

    const aspect = availablePresets.find((item) => item.value === preset)?.aspect;
    const newHeight = aspect != null ? Math.round(newWidth / aspect) : height;

    onChange({
      preset: preset,
      width: newWidth,
      height: newHeight,
    });
  }

  const adjustSmallerWidth = () => {
    const percent = -1;
    adjustWidth(Math.round(width * (100 + percent) / 100));
  }

  const adjustLargerWidth = () => {
    const percent = 1;
    adjustWidth(Math.round(width * (100 + percent) / 100));
  }

  const adjustSmallerHeight = () => {
    const percent = -1;
    adjustHeight(Math.round(height * (100 + percent) / 100));
  }

  const adjustLargerHeight = () => {
    const percent = 1;
    adjustHeight(Math.round(height * (100 + percent) / 100));
  }

  return (
    <>
      <div className="flex gap-2 items-center flex-wrap">
        <Radio.Group
          value={preset}
          onChange={(e) => changePreset(e.target.value)}
        >
          {availablePresets.map((option) => (
            <Radio.Button key={option.value} value={option.value}>{option.label}</Radio.Button>
          ))}
        </Radio.Group>
        <span className="flex gap-2 items-center">
          <InputNumber min={0} step={10}
            value={width}
            onChange={(value) => adjustWidth(value)}
            disabled={defaultValue.widthReadonly}
          />
          x
          <InputNumber min={0} step={10}
            value={height}
            onChange={(value) => adjustHeight(value)}
          />
          {preset == 'CUSTOM'
            ?
            <React.Fragment>
              <Button onClick={adjustSmallerWidth} disabled={defaultValue.widthReadonly}><LeftOutlined /></Button>
              <Button onClick={adjustLargerWidth} disabled={defaultValue.widthReadonly}><RightOutlined /></Button>
              <Button onClick={adjustSmallerHeight}><DownOutlined /></Button>
              <Button onClick={adjustLargerHeight}><UpOutlined /></Button>
            </React.Fragment>
            :
            <React.Fragment>
              <Button onClick={adjustSizeSmaller}>-</Button>
              <Button onClick={adjustSizeLarger}
              // onMouseDown={() => console.log('handleMouseDown')}
              // onMouseUp={() => console.log('handleMouseUp')}
              // onMouseLeave={() => console.log('handleMouseUp')}
              >+</Button>
            </React.Fragment>
          }
            <Button onClick={reset}>Reset</Button>
        </span>
      </div>
    </>
  )
}

export default SizeSelector