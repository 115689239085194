import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';

const Awards = () => {
  const entities = useEntities();
  const filterGroups = null;
  const showColumns = [
    'name', 
    'nick', 
    'short', 
    'description', 
    'foundDate', 
    'type',
  ];

  return (
    <HmsEntityTableController
      entity={entities.Award}
      filterGroups={filterGroups}
      showColumns={showColumns}
    />
  )
}

export default Awards