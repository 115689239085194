import { Checkbox, Input } from 'antd';
import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import React, { useMemo, useState } from 'react'
import PhaseSelector from './Filters/PhaseSelector';
import IGHeader from './Parts/IGHeader';
import IGImg from './Parts/IGImg';
import CompetitionLogo from './Parts/CompetitionLogo';

import "@fontsource/bebas-neue";
import "@fontsource/audiowide";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateFormatIG, defaultDateIG, defaultPhaseIdIG } from './IGconfig';
import GameScoreCard from './Parts/GameScoreCard';
import DebugPrinter from 'components/Debug/DebugPrinter';
import DateSelector from 'components/Infographics/Filters/DateSelector';
import usePersistentStore from 'store/persistentStore';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import { sortDateAndTime } from 'utils/sorters';
dayjs.extend(customParseFormat);

const IG2_DayRecap = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const date = usePersistentStore((state) => state.get('ig-date')) ?? dayjs(new Date()).format(dateFormatIG);
  const setDate = usePersistentStore((state) => state.set('ig-date'));
  const bgPictureUrl = usePersistentStore((state) => state.get('ig-dayrecap-bg-url'));
  const setBgPictureUrl = usePersistentStore((state) => state.set('ig-dayrecap-bg-url'));
  const size = usePersistentStore((state) => state.get('ig-dayrecap-size'));
  const setSize = usePersistentStore((state) => state.set('ig-dayrecap-size'));
  const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 1100, height: 1955, preset: 'PORTRAIT' };

  const { phaseIdsAll } = usePhaseIdTranslator(membership);
  const seasonId = membership?.seasonId;

  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');

  const { data: gamesToday } = useFetchEntities(
    phaseIdsAll != null && date != null && 'Game',
    {
      filters: { startDate: date, phaseId: phaseIdsAll },
      relations: 'Venue(name,logoUrl),HomeTeam(name,logoUrl),AwayTeam(name,logoUrl),Phase>Group(name,color)',
      extraAttributes: 'HomeTeamGoals,AwayTeamGoals',
    }
  );

  const metadata = {
    SeasonName: seasonDetail?.name,
    CompetitionName: seasonDetail?.Competition?.name,
    CompetitionLogo:
      seasonDetail?.Competition?.logoUrl?.replace(
        "[size]",
        "cropped_md",
      ),
    Date: date,
  };

  const data = useMemo(() => {
    return gamesToday?.
      sort(
        (g1, g2) => sortDateAndTime(g1.startDate, g1.startTime, g2.startDate, g2.startTime)
      )
      .map((game) => ({
        VenueName: game.Venue?.name,
        VenueLogo: game.Venue?.logoUrl?.replace("[size]", "cropped_md"),
        HomeTeamLogo: game.HomeTeam?.logoUrl?.replace("[size]", "cropped_md"),
        AwayTeamLogo: game.AwayTeam?.logoUrl?.replace("[size]", "cropped_md"),
        Score: `${game.HomeTeamGoals}:${game.AwayTeamGoals}`,
        GroupName: game.Phase?.Group?.name,
        GroupColor: game.Phase?.Group?.color,
      })) ?? [];
  }, [gamesToday]);


  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Choose date:</span>
        <div>
          <DateSelector
            value={dayjs(date, dateFormatIG)}
            onChange={(dateString) => { setDate(dateString) }}
          />
        </div>

        <span>Background picture url:</span>
        <Input value={bgPictureUrl} onChange={(e) => setBgPictureUrl(e.target.value)} />

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[metadata, gamesToday, date]}></DebugPrinter>)}

      {date && phaseIdsAll &&
        <article
          ref={exportRef}
          className="mx-auto h-[1000px] w-[1100px] bg-hockey-page-bg font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
        >
          <main className="grid h-full grid-cols-5">
            <div className="col-span-3 flex flex-col p-8 pr-0">
              <IGHeader
                className="border-b-2 border-white"
                title={`${metadata.CompetitionName} daily recap`}
                date={metadata.Date}
              />

              <div className="flex h-full flex-col items-center justify-between">
                <div className="flex flex-col gap-2">
                  {data.map((game, index) => (
                    <GameScoreCard key={index} game={game} />
                  ))}
                </div>
                <CompetitionLogo
                  src={metadata.CompetitionLogo}
                  title={metadata.CompetitionName}
                  season={metadata.SeasonName}
                />
              </div>
            </div>
            <IGImg
              src={bgPictureUrl ?? ''}
              alt=""
              className="col-span-2 h-full w-full object-cover"
              style={{
                clipPath: "polygon(50px 0, 100% 0, 100% 100%, 0% 100%)",
              }}
            />
          </main>
        </article>
      }
    </>
  )
}

export default IG2_DayRecap