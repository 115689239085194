import { Checkbox, Input } from 'antd';
import useFetchEntity from 'hooks/useFetchEntity';
import React, { useMemo, useState } from 'react'

import "@fontsource/bebas-neue";
import "@fontsource/audiowide";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";

import dayjs from 'dayjs';
import DebugPrinter from 'components/Debug/DebugPrinter';
import CompetitionLogo from './Parts/CompetitionLogo';
import IGImg from './Parts/IGImg';
import TableStandings from './Parts/TableStandings';
import IGHeader from './Parts/IGHeader';
import { dateFormatIG, defaultPhaseIdIG } from './IGconfig';
import PhaseSelector from './Filters/PhaseSelector';
import useFetchDataWithOrg from 'hooks/useFetchDataWithOrg';
import usePersistentStore from 'store/persistentStore';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';

const IG5_StandingsByPoints = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const bgPictureUrl = usePersistentStore((state) => state.get('ig-standings-bg-url'));
  const setBgPictureUrl = usePersistentStore((state) => state.set('ig-standings-bg-url'));
  const size = usePersistentStore((state) => state.get('ig-standings-size'));
  const setSize = usePersistentStore((state) => state.set('ig-standings-size'));
  const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 625, height: 1111, preset: 'PORTRAIT' };

  const { phaseIdsAll } = usePhaseIdTranslator(membership);
  const groupId = membership?.groupId;
  const seasonId = membership?.seasonId;
  
  const { data: groupDetail } = useFetchEntity('Group', groupId, 'Season>Competition');
  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');

  const teamStandings = useFetchDataWithOrg(phaseIdsAll
    ? `/admin/tableStandings?phaseIds=${phaseIdsAll?.join(',')}`
    : null);

  const metadata = useMemo(() => {
    return {
      GroupName: groupDetail?.name,
      GroupColor: groupDetail?.color,
      SeasonName: seasonDetail?.name,
      CompetitionName: seasonDetail?.Competition?.name,
      CompetitionLogo:
      seasonDetail?.Competition?.logoUrl?.replace(
          "[size]",
          "cropped_md",
        ),
      Date: dayjs().format(dateFormatIG),
    };
  }, [groupDetail, seasonDetail]);

  const data = useMemo(() => {
    const data = teamStandings?.data?.map((team) => ({
      Name: team["Team Name"],
      Logo: team["TeamLogo"]?.replace("[size]", "cropped_md"),
      Z: team["Total Games"],
      V: team["Wins"],
      P: team["Losses"],
      R: team["Draws"],
      Score: team["Score"],
      B: team["Total Points"],
      TM: team["Total Penalty Minutes"],
    })) ?? [];

    const sorted = data.sort((a, b) => {
      return b.B - a.B;
    });

    return sorted;
  }, [teamStandings]);


  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Background picture url:</span>
        <Input value={bgPictureUrl} onChange={(e) => setBgPictureUrl(e.target.value)} />

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[metadata, teamStandings]}></DebugPrinter>)}

      {phaseIdsAll &&
        <article
          ref={exportRef}
          className="mx-auto w-[625px] bg-hockey-page-bg font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
        >
          <div className="flex h-full flex-col items-center justify-between">

            <div className="p-8">
              <IGHeader
                title={`Standings - ${metadata.CompetitionName} ${metadata.SeasonName}`}
                league={metadata.GroupName}
                leagueStyle={{ color: metadata.GroupColor ?? '#777777' }}
                small
                date={metadata.Date}
              />
              <TableStandings data={data} />
            </div>

            <div>
              <div className="relative h-[80px]">
                <IGImg
                  src="/images/most-points.png"
                  className="absolute top-0 z-10 -mt-14 h-[250px]"
                  alt=""
                />
              </div>
              <IGImg
                src={bgPictureUrl}
                alt="Background picture"
                className="aspect-[24/10] w-full object-cover"
                style={{
                  clipPath: "polygon(450px 0, 100% 0, 100% 100%, 0 100%, 0 70px)",
                }}
              />
              <CompetitionLogo
                className="mx-auto scale-75 p-4"
                src={metadata.CompetitionLogo}
                title={metadata.CompetitionName}
                season={metadata.SeasonName}
              />
            </div>
          </div>
        </article>
      }
    </>
  );
};

export default IG5_StandingsByPoints;
