import React, { useState, useEffect, createContext } from 'react';
import useLoginStore from 'store/loginStore';

const LoginContext = createContext({
});

const LoginProvider = ({ children }) => {
    const isLoggedIn = useLoginStore(s => s.isLoggedIn);
    const updateIsLoggedIn = useLoginStore(s => s.updateIsLoggedIn);

    useEffect(() => {
        updateIsLoggedIn();
    }, []);

    return (
        <LoginContext.Provider value={{ isLoggedIn }}>
            {children}
        </LoginContext.Provider>
    );
};

export { LoginContext, LoginProvider };
