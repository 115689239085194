import React from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import { HmsFormContext } from 'components/Form/HmsFormContext';
import FormInput from 'components/Form/FormInput';
import FormDatePicker from 'components/Form/FormDatePicker';
import FormSelect from 'components/Form/FormSelect';
import FormTextArea from 'components/Form/FormTextArea';
import FormInputNumber from 'components/Form/FormInputNumber';
import FormColorList from 'components/Form/FormColorList';
import FormTimePicker from 'components/Form/FormTimePicker';
import FormColorPicker from 'components/Form/FormColorPicker';
import FormCheckbox from 'components/Form/FormCheckbox';
import useLists from 'hooks/useLists';
import FormSwitch from 'components/Form/FormSwitch';
import FormYesNo from 'components/Form/FormYesNo';
import FormCheckboxGroup from 'components/Form/FormCheckboxGroup';
import FormHtmlEditor from 'components/Form/FormHtmlEditor';

const AutoField = ({ name, type, field, ...props }) => {
    const { colors, countries } = useLists();

    if (!field) {
        return <span>Unknown field: {name}</span>
    }

    switch (type ?? field.type) {
        case 'COUNTRY_LIST':
            return <FormSelect
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                allowClear={field.allowNull !== false}
                fieldItemProps={{disabled: field.readonly}}
                options={countries}
                {...props}
            />
        case 'STRING':
            return <FormInput
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                fieldItemProps={{maxLength: 255, disabled: field.readonly}}
                {...props}
            />;
        case 'NVARCHAR(1024)':
        case 'NVARCHAR(2048)':
            return <FormInput
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                fieldItemProps={{maxLength: 1024, disabled: field.readonly}}
                {...props}
            />;
        case 'LONG_STRING':
            return <FormTextArea
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />;
        case 'DATEONLY':
            return <FormDatePicker
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />
        case 'TIME':
            return <FormTimePicker
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />
        case 'INTEGER':
        case 'DOUBLE':
            return <FormInputNumber
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />
        // case 'COLOR_LIST':
        //     return <FormColorList
        //         name={name}
        //         label={field.displayName}
        //         required={field.allowNull === false || props.required == true}
        //         colors={colors}
        //         fieldItemProps={{disabled: field.readonly}}
        //         {...props}
        //     />
        case 'COLOR_LIST':
        case 'COLOR':
            return <FormColorPicker
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                colors={colors}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />                
        case 'ENUM':
        case 'LIST':
            return <FormSelect
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                allowClear={field.allowNull !== false}
                options={field.options}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />
        case 'BOOLEAN':
            return <FormCheckbox
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                allowClear={field.allowNull !== false}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />                
        case 'BOOLEAN_SWITCH':
            return <FormSwitch
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                allowClear={field.allowNull !== false}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />
        case 'BOOLEAN_YESNO':
            return <FormYesNo
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                allowClear={field.allowNull !== false}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />
        case 'UNIQUEIDENTIFIER':
            return <FormSelect
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                allowClear={field.allowNull !== false}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />
        case 'CHECKBOX_GROUP':
            return <FormCheckboxGroup
                name={name}
                label={field.displayName}
                required={field.allowNull === false || props.required == true}
                allowClear={field.allowNull !== false}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />
        case 'HTML':
            return <FormHtmlEditor
                name={name}
                label={field.displayName}
                fieldItemProps={{disabled: field.readonly}}
                {...props}
            />
        default:
            return <span>Unknown field type: {JSON.stringify(type ?? field.type)}</span>
    }
}

const HmsField = ({ name, type, ...props }) => {
    return (
        <HmsFormContext.Consumer>
            {({ entity }) =>
                <>
                    {(entity.fields && entity.fields[name]) && <AutoField name={name} type={type} field={entity.fields[name]} {...props}></AutoField>}
                    {(!entity.fields || !entity.fields[name]) && <span>Unknown field: {name} in entity {entity?.name}</span>}
                </>
            }
        </HmsFormContext.Consumer>
    )
}

export default HmsField