import { useEffect, useMemo, useState } from 'react';
import HmsTable from 'components/HmsTable';
import HmsTableLayout from 'components/HmsTableLayout';
import useFetchEntities from 'hooks/useFetchEntities';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';
import usePageTitle from 'hooks/usePageTitle';
import { Radio } from 'antd';
import useSearchParamsMulti from 'hooks/useSearchParamsMulti';
import { autoAddIdColumnForExport, autoAddLinkColumnForExport, autoAddLogoColumnForExport, autoAttributesFromColumns, autoExtraAttributesFromColumns, autoRelationsFromColumns } from 'utils/autoColumns';
import HmsTableWithFilters from 'components/HmsTableWithFilters';
import useIsMobile from 'hooks/useIsMobile';
import extractTextFromJSX from 'utils/extractTextFromJsx';

const HmsEntityTableController = ({ entity, relations: relationsParam, onlyAttributes, extraAttributes: extraAttributesParam, filterGroups, showColumns, columnSets: columnSetsParam, onRowClickLink, canCreate = true, createUrl = null, renderMobileRow, extraButtonBarItems }) => {
  const { useSearchParamSingle } = useSearchParamsMulti();

  const columnSets = useMemo(() => {
    return columnSetsParam?.map(cs => ({
      ...cs,
      key: cs.key ?? extractTextFromJSX(cs.name)?.replace(' ', ''),
    }));
  }, [columnSetsParam]);

  const [controllerColumnSet, setControllerColumnSet] = useSearchParamSingle('controllerColumnSet', columnSets?.[0]?.key);
  const currentColumnSet = columnSets?.find(cs => cs.key == controllerColumnSet);

  const [filters, setFilters] = useState(currentColumnSet?.filters ?? {});
  const isMobile = useIsMobile();
  const isMobileVersion = isMobile && renderMobileRow;

  const columns =
    currentColumnSet?.columns
    ?? showColumns;

  const tableColumns = entityColumnsToAntColumns(entity, columns);

  autoAddIdColumnForExport(entity, tableColumns);
  autoAddLinkColumnForExport(entity, tableColumns);
  autoAddLogoColumnForExport(entity, tableColumns);

  const relations =
    currentColumnSet?.relations 
    ?? relationsParam 
    ?? autoRelationsFromColumns(entity, tableColumns);

  const extraAttributes = 
    currentColumnSet?.extraAttributes 
    ?? extraAttributesParam 
    ?? autoExtraAttributesFromColumns(entity, tableColumns);

  const onlyAttributesFinal = 
    onlyAttributes 
    ?? currentColumnSet?.onlyAttributes 
    ?? autoAttributesFromColumns(entity, columns);

  const { data, isError } = useFetchEntities(entity.name, { filters, relations, extraAttributes, onlyAttributes: onlyAttributesFinal });
  usePageTitle(entity.displayNamePlural);

  const onFiltersChanged = (newFilters) => {
    setFilters(newFilters);
  }

  const setColumnSet = (key) => {
    setControllerColumnSet(key);
    const newColumnSet = columnSets.find(cs => cs.key == key);
    setFilters(newColumnSet?.filters)
  }

  // TODO: remove shitFilter !!!
  const shitFilter = columnSets?.find(cs => cs.key == controllerColumnSet)?.shitFilter;

  const ColumnSetSelector = () => (
    <Radio.Group
      size="small"
      value={controllerColumnSet}
      onChange={(e) => setColumnSet(e.target.value)}
    >
      {columnSets.map((cs) => (
        <Radio.Button
          key={cs.name}
          value={cs.key ?? cs.name}
        >
          {cs.name}
        </Radio.Button>
      ))}
    </Radio.Group>
  )

  // TODO: replace with useMemo
  const filteredData = shitFilter ? data?.filter(shitFilter) : data;

  return (
    <>
      <HmsTableLayout breadcrumbs={entity.entitiesBreadcrumb}>
        <HmsTableWithFilters
          persistentId={entity.name}
          exportEnabled={true}
          filterEnabled={!isMobileVersion}
          columnSelectorEnabled={true}
          columns={tableColumns}
          extraButtonBarItems={
            ({ filteredData }) => {
              return (
                (columnSets || extraButtonBarItems) &&
                <>
                  {columnSets && <ColumnSetSelector />}
                  {typeof extraButtonBarItems == 'function' ? extraButtonBarItems({ filteredData }) : extraButtonBarItems}
                </>
              )
            }
          }
          data={filteredData}
          isError={isError}
          title={entity.displayNamePlural}
          exportPrefix={entity.displayNamePlural + (currentColumnSet ? '-' + currentColumnSet?.key : '')}
          createButtonLink={canCreate && (createUrl ?? entity.newEntityUrl())}
          createButtonType="primary"
          filterGroups={filterGroups}
          onFiltersChanged={onFiltersChanged}
          onRowClickedLink={onRowClickLink ?? ((record) => entity.entityUrl(record))}
          renderMobileRow={renderMobileRow}
          fixedX={true}
          fixedY={true}
        />
      </HmsTableLayout>

    </>
  )
}

export default HmsEntityTableController