import { useState, useEffect } from 'react'
import icons from "utils/icons";
import { stringToTitleCase } from 'utils/stringTo';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useNotificationStore from 'store/notificationStore';
import useFields from 'hooks/useFields';
import useGlobalStore from 'store/globalStore';
import { formatDate, formatCreatedBy, formatDateTime, formatTime, formatWeekday, generateEntityColumns as generateEntityColumnsFunc, generateEntityColumnInner, formatTimeShort } from 'utils/generateEntityColumns';
import { sortDateAndTime, sortString } from 'utils/sorters';
import PaymentTag from 'components/PaymentTag';
import useLists from 'hooks/useLists';
import { Checkbox, Tag, Tooltip } from 'antd';
import { sorted, unique } from 'utils/oneLiners';
import PlayerLevelTag from 'components/PlayerLevelTag';
import EntityLogo from 'components/EntityLogo';
import validateFlickrAlbumUrl from 'utils/validateFlickrUrl';
import { CheckCircleOutlined, CloseCircleOutlined, DollarCircleOutlined, CheckCircleFilled, CloseCircleFilled, WarningFilled, EyeOutlined } from '@ant-design/icons';
import renderWithTooltipIfNeeded from 'utils/renderWithTooltipIfNeeded';
import useLoginStore from 'store/loginStore';
import HmsStatusTag from 'components/HmsStatusTag';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';
import { getStatusText } from 'utils/status';
import { render } from '@testing-library/react';
import { FaPerson } from "react-icons/fa6";
import moment from 'moment';
import getColorName from 'utils/getColorName';

dayjs.extend(utc);

const suppressZero = (value) => {
    return (<span className={value == 0 ? 'text-gray-300' : ''}>{value == 0 ? '0' : value}</span>);
}

function useEntities() {
    const { userRoles, countries, colors } = useLists();
    const fields = useFields();
    const currentUserRoles = useLoginStore(s => s.roles);
    const putEntity = useGlobalStore(s => s.putEntity);
    const postEntity = useGlobalStore(s => s.postEntity);
    const postEntities = useGlobalStore(s => s.postEntities);
    const patchEntity = useGlobalStore(s => s.patchEntity);
    const deleteEntity = useGlobalStore(s => s.deleteEntity);
    const deleteEntities = useGlobalStore(s => s.deleteEntities);
    const cloneEntity = useGlobalStore(s => s.cloneEntity);
    const postRelation = useGlobalStore(s => s.postRelation);
    const deleteRelation = useGlobalStore(s => s.deleteRelation);
    const addMessage = useNotificationStore((state) => state.addMessage);
    const isAdmin = currentUserRoles.indexOf('ADMIN') != -1;

    const generateEntityColumns = (entity, fields) => {
        return generateEntityColumnsFunc(entity, fields, { countries });
    }

    const colorColumn = (fieldName) => {
        return {
            align: 'center',
            render: (record) => {
                const color = record[fieldName]?.toUpperCase();
                const label = getColorName(color);
                if (color == null || color == '') {
                    return (
                        ''
                    );
                }
                return (
                    <div className="flex justify-center">
                        <Tooltip title={
                            <div className="grid grid-cols-[auto_1fr] gap-x-2 gap-y-1">
                                <div>Name:</div>
                                <div>{label}</div>
                                <div>HEX:</div>
                                <div>{color}</div>
                            </div>
                        }>
                            <span className="border-[1px] rounded-[6px] border-[#ddd] w-8 h-8 flex items-center justify-center">
                                <span className={`inline-block w-6 h-6 rounded-[4px]`} style={{ backgroundColor: color }} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            searchValue: (record) => {
                const color = record[fieldName]?.toUpperCase();
                const label = getColorName(color);
                return color ? `${color} - ${label}` : null;
            },
            filterValue: (record) => {
                const color = record[fieldName]?.toUpperCase();
                const label = getColorName(color);
                return color ? `${color} - ${label}` : null;
            },
            exportValue: (record) => {
                const color = record[fieldName]?.toUpperCase();
                const label = getColorName(color);
                return color ? `${color} - ${label}` : null;
            },
            filterType: 'list',
            requiredAttributes: [fieldName],
        }
    }
    
    const entities = {
        Organization: {
            ...generateEntity("Organization", { urlPrefix: '/settings' }),
            defaultValues: {
                status: 'ACTIVE',
            },
            columns: {
                ...generateEntityColumns('Organization', fields['Organization']),
                'NameWithLogo': {
                    title: 'Name',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.name, b.name),
                    render: (record) =>
                        <span className="flex items-center gap-3">
                            <span>
                                <EntityLogo entity={record} size={32} />
                            </span>
                            {record?.name}
                        </span>,
                    searchValue: (record) => record?.name ?? '',
                    requiredAttributes: ['name', 'logoUrl'],
                },
            },
            defaultColumnName: 'NameWithLogo',
            originalLink: null,
        },
        User: {
            ...generateEntity("User", { urlPrefix: '/settings' }),
            getTitle: (record) => (record.firstName + ' ' + record.lastName),
            originalLink: null,
            columns: {
                ...generateEntityColumns('User', fields['User']),
                'Name': {
                    title: 'Name',
                    render: (record) => record.firstName + ' ' + record.lastName,
                    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
                    requiredAttributes: ['firstName', 'lastName'],
                },
                'Roles': {
                    title: 'Roles',
                    render: (record) =>
                        <span className="flex flex-col gap-2">
                            {record.roles?.split(',')?.map((r, idx) => (
                                <span key={idx} className="flex items-center gap-2"><Tag>{userRoles.find(o => o.value == r)?.label}</Tag></span>
                            ))}
                        </span>,
                    requiredAttributes: ['roles'],
                    filterType: 'list',
                    filterValues: (r) => r.roles?.split(','),
                },
                'Organizations': {
                    title: 'Organizations',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.Organizations?.map(o => o.name).join(' '), b?.Organizations?.map(o => o.name).join(' ')),
                    render: (record) =>
                        <span className="flex flex-wrap gap-2">
                            {record.Organizations?.map((o, idx) => (
                                <span key={idx} className="flex items-center gap-2"><EntityLogo entity={o} size={32} />{o.nick ?? o.short ?? o.name}</span>
                            ))}
                        </span>,
                    searchValue: (record) => record?.Organizations?.map(o => o.name).join(' ') ?? '',
                    exportValue: (record) => record?.Organizations?.map(o => o.name).join(', ') ?? '',
                    requiredRelations: ['Organizations'],
                    filterType: 'list',
                    filterValues: (r) => r?.Organizations?.map(o => o.name),
                },
                'ManagedTeams': {
                    title: 'Managed Teams',
                    render: (record) =>
                        <span className="flex flex-col gap-2">
                            {record.ManagedTeams?.map((t, idx) => (
                                <span key={idx} className="flex items-center gap-2"><EntityLogo entity={t} size={32} />{t.nick ?? t.short ?? t.name}</span>
                            ))}
                        </span>,
                    requiredRelations: ['ManagedTeams'],
                    filterType: 'list',
                    filterValues: (r) => r?.ManagedTeams?.map(t => t.name),
                },
            },
        },
        Competition: {
            ...generateEntity("Competition"),
            initNew: (parentData, data) => {
                return {
                    ...data,
                    status: 'RUNNING',
                    foundDate: dayjs(new Date()),
                }
            },
            columns: {
                ...generateEntityColumns('Competition', fields['Competition']),
                'NameWithLogo': {
                    title: 'Name',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.name, b.name),
                    render: (record) =>
                        <span className="flex items-center gap-3">
                            <span>
                                <EntityLogo entity={record} size={32} />
                            </span>
                            {record?.name}
                        </span>,
                    searchValue: (record) => record?.name ?? '',
                    requiredAttributes: ['name', 'logoUrl'],
                },
            },
            defaultColumnName: 'NameWithLogo',
            defaultColumnsForRelations: [
                'NameWithLogo',
                'nick',
                'short',
                'foundDate',
                'status',
            ],
            createRelations: async (sourceId, targetEntity, targetIds) => {
                console.log(targetEntity, targetIds);
                await postRelation(entities.Competition, sourceId, targetEntity, targetIds);
                // return new Promise(r => setTimeout(r, 1000))
            },
        },
        Season: {
            ...generateEntity("Season"),
            columns: {
                ...generateEntityColumns('Season', fields['Season']),
                'Competition.Name': {
                    title: 'Competition',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.Competition?.name, b?.Competition?.name),
                    render: (record) => record?.Competition?.name,
                    requiredRelations: ['Competition(name)'],
                },
            },
            initNew: (parentData, data) => {
                return {
                    ...data,
                    competitionId: parentData?.competitionId,
                    status: 'RUNNING',
                }
            },
            entityBreadcrumb: (data, parentData) => [
                { title: 'Home', icon: icons.Home, link: '/' },
                { title: 'Competitions', icon: icons.Competition, link: '/competitions' },
                { title: (data?.Competition ?? parentData)?.name, icon: icons.Competition, link: `/competitions/${(data?.Competition ?? parentData)?.competitionId}` },
                { title: data?.seasonId ? data?.name : 'New', icon: icons.Season },
            ],
            fetchRelations: 'Competition',
            defaultColumnsForRelations: [
                'Competition.Name',
                'name',
                'nick',
                'short',
                'startDate',
                'endDate',
                'status',
            ],
            defaultColumnsForAddRelationsModal: [
                'Competition.Name',
                'name',
            ],
        },
        Group: {
            ...generateEntity("Group"),
            columns: {
                ...generateEntityColumns('Group', fields['Group']),
                'Name': {
                    title: 'Group',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.name, b?.name),
                    render: (record) => record?.name,
                    requiredAttributes: ['name'],
                },
                'Season.Name': {
                    title: 'Season',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.Season?.name, b?.Season?.name),
                    render: (record) => record?.Season?.name,
                    requiredRelations: ['Season(name)'],
                },
                'Season.Competition.Name': {
                    title: 'Competition',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.Season?.Competition?.name, b?.Season?.Competition?.name),
                    render: (record) => record?.Season?.Competition?.name,
                    requiredRelations: ['Season>Competition(name)'],
                },
                'Color': {
                    title: 'Color',
                    ...colorColumn('color'),
                    width: 130,
                },
            },
            initNew: (parentData, data) => {
                return {
                    ...data,
                    seasonId: parentData?.seasonId,
                    status: 'RUNNING',
                }
            },
            entityBreadcrumb: (data, parentData) => [
                { title: 'Home', icon: icons.Home, link: '/' },
                { title: 'Competitions', icon: icons.Competition, link: '/competitions' },
                { title: (data?.Season ?? parentData)?.Competition?.name, icon: icons.Competition, link: `/competitions/${((data?.Season ?? parentData)?.Competition?.competitionId)}` },
                { title: (data?.Season ?? parentData)?.name, icon: icons.Season, link: `/seasons/${((data?.Season ?? parentData)?.seasonId)}` },
                { title: data?.groupId ? data?.name : 'New', icon: icons.Group },
            ],
            fetchRelations: 'Season,Season>Competition',
            originalLink: null,
            defaultColumnsForRelations: [
                'Season.Competition.Name',
                'Season.Name',
                'name',
                'nick',
                'short',
                'Color',
                'teamsLimit',
                'status',
            ],
            defaultColumnsForAddRelationsModal: [
                'Season.Competition.Name',
                'Season.Name',
                'name',
            ],
        },
        Phase: {
            ...generateEntity("Phase"),
            columns: {
                ...generateEntityColumns('Phase', fields['Phase']),
                'NameWithLink': {
                    title: 'Name',
                    sortDirections: ['ascend', 'descend'],
                    width: 200,
                    sorter: (a, b) => sortString(a.name, b.name),
                    render: (record) =>
                        <a href={entities.Phase.entityUrl(record)} target="_blank">
                            {record?.name}
                        </a>,
                    searchValue: (record) => record?.name ?? '',
                    requiredAttributes: ['name', 'logoUrl'],
                },
                'Group.Name': {
                    title: 'Group',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.Group?.name, b?.Group?.name),
                    render: (record) => record?.Group?.name,
                    requiredRelations: ['Group(name)'],
                },
                'Group.Season.Name': {
                    title: 'Season',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.Group?.Season?.name, b?.Group?.Season?.name),
                    render: (record) => record?.Group?.Season?.name,
                    requiredRelations: ['Group>Season(name)'],
                },
                'Group.Season.Competition.Name': {
                    title: 'Competition',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.Group?.Season?.Competition?.name, b?.Group?.Season?.Competition?.name),
                    render: (record) => record?.Group?.Season?.Competition?.name,
                    requiredRelations: ['Group>Season>Competition(name)'],
                },
                'TeamCount': {
                    title: 'Team Count',
                    // sortDirections: ['ascend', 'descend'],
                    // sorter: (a, b) => sortString(a?.Group?.Season?.Competition?.name, b?.Group?.Season?.Competition?.name),
                    render: (record) => record?.Teams?.length ?? '-',
                    requiredRelations: ['Teams(teamId)'],
                }
            },
            initNew: (parentData, data) => {
                return {
                    ...data,
                    groupId: parentData?.groupId,
                    status: 'RUNNING',
                }
            },
            entityBreadcrumb: (data, parentData) => [
                { title: 'Home', icon: icons.Home, link: '/' },
                { title: 'Competitions', icon: icons.Competition, link: '/competitions' },
                { title: (data?.Group ?? parentData)?.Season?.Competition?.name, icon: icons.Competition, link: `/competitions/${(data?.Group ?? parentData)?.Season?.Competition?.competitionId}` },
                { title: (data?.Group ?? parentData)?.Season?.name, icon: icons.Season, link: `/seasons/${(data?.Group ?? parentData)?.Season?.seasonId}` },
                { title: (data?.Group ?? parentData)?.name, icon: icons.Group, link: `/groups/${(data?.Group ?? parentData)?.groupId}` },
                { title: data?.phaseId ? data?.name : 'New', icon: icons.Phase },
            ],
            fetchRelations: 'Group,Group>Season,Group>Season>Competition',
            originalLink: null,
            defaultColumnsForRelations: [
                'Group.Season.Competition.Name',
                'Group.Season.Name',
                'Group.Name',
                'name',
                'nick',
                'short',
                'startDate',
                'endDate',
                'status',
            ],
            defaultColumnsForAddRelationsModal: [
                'Group.Season.Competition.Name',
                'Group.Season.Name',
                'Group.Name',
                'name',
            ],
        },
        Sponsor: {
            ...generateEntity("Sponsor"),
            columns: {
                ...generateEntityColumns('Sponsor', fields['Sponsor']),
                'NameWithLogo': {
                    title: 'Name',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.name, b.name),
                    render: (record) =>
                        <span className="flex items-center gap-3">
                            <span>
                                <EntityLogo entity={record} size={32} />
                            </span>
                            {record?.name}
                        </span>,
                    searchValue: (record) => record?.name ?? '',
                    requiredAttributes: ['name', 'logoUrl'],
                },
            },
            defaultColumnName: 'NameWithLogo',
            defaultColumnsForRelations: [
                'NameWithLogo', 
                'nick', 
                'short',
                'claim',
                'web',
                'description',
                'legalName',
            ],
        },
        Venue: {
            ...generateEntity("Venue"),
            columns: {
                ...generateEntityColumns('Venue', fields['Venue']),
                'NameWithLogo': {
                    title: 'Name',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.name, b.name),
                    render: (record) =>
                        <span className="flex items-center gap-3">
                            <span>
                                <EntityLogo entity={record} size={32} />
                            </span>
                            {record?.name}
                        </span>,
                    searchValue: (record) => record?.name ?? '',
                    requiredAttributes: ['name', 'logoUrl'],
                },
            },
            defaultColumnName: 'NameWithLogo',
            defaultColumnsForRelations: [
                'NameWithLogo', 
                'nick', 
                'short', 
                'web', 
                'description', 
                'foundDate', 
                'location', 
                'capacity'
            ],
        },
        Award: {
            ...generateEntity("Award"),
            defaultColumnsForRelations: [
                'name', 
                'nick', 
                'short', 
                'description', 
                'foundDate', 
                'type',
            ],
        },
        Team: {
            ...generateEntity("Team"),
            columns: {
                ...generateEntityColumns('Team', fields['Team']),
                'NameWithLogo': {
                    title: 'Name',
                    sortDirections: ['ascend', 'descend'],
                    width: 200,
                    sorter: (a, b) => sortString(a.name, b.name),
                    render: (record) =>
                        <span className="flex items-center gap-3">
                            <span>
                                <EntityLogo entity={record} size={32} />
                            </span>
                            {record?.name}
                        </span>,
                    searchValue: (record) => record?.name ?? '',
                    requiredAttributes: ['name', 'logoUrl'],
                },
                'NameWithLogoClickable': {
                    title: 'Name',
                    sortDirections: ['ascend', 'descend'],
                    width: 200,
                    sorter: (a, b) => sortString(a.name, b.name),
                    render: (record) =>
                        <a href={entities.Team.entityUrl(record)} target="_blank">
                            <span className="flex items-center gap-3">
                                <span>
                                    <EntityLogo entity={record} size={32} />
                                </span>
                                {record?.name}
                            </span>
                        </a>,
                    searchValue: (record) => record?.name ?? '',
                    requiredAttributes: ['name', 'logoUrl'],
                },
                'TeamColor1': {
                    title: 'Primary Color',
                    ...colorColumn('teamColor1'),
                    width: 130,
                },
                'TeamColor2': {
                    title: 'Secondary Color',
                    ...colorColumn('teamColor2'),
                    width: 140,
                },
                'TeamColor3': {
                    title: 'Tertiary Color',
                    ...colorColumn('teamColor3'),
                    width: 130,
                },
                'JerseyColor1': {
                    title: 'Primary Jersey',
                    ...colorColumn('jerseyColor1'),
                    width: 130,
                },
                'JerseyColor2': {
                    title: 'Secondary Jersey',
                    ...colorColumn('jerseyColor2'),
                    width: 140,
                },
                'JerseyColor3': {
                    title: 'Tertiary Jersey',
                    ...colorColumn('jerseyColor3'),
                    width: 130,
                },
                'NewChangeRequestCount': {
                    title: 'Change Requests',
                    width: 150,
                    render: (r) => (
                        <span className="flex justify-center">
                            {r.NewChangeRequestCount && <Tag>{r.NewChangeRequestCount}</Tag>}
                        </span>
                    ),
                    requiredExtraAttributes: ['NewChangeRequestCount'],
                },            
                'Status': {
                    title: 'Status',
                    width: 130,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.status), ('' + b.status)),
                    render: (record) => <HmsStatusTag status={record.status} />,
                    searchValue: (record) => '' + record.status,
                    exportValue: (record) => '' + getStatusText(record.status),
                    requiredAttributes: ['status'],
                    filterType: 'list',
                },
                'ActivePlayerCount': {
                    title: 'Active Players',
                    width: 130,
                    render: (record) => (
                        <Tooltip title={`${record.ActivePlayerCount} Player${record.ActivePlayerCount != 1 ? 's' : ''}`}>
                            <Tag color={
                                record.ActivePlayerCount <= 0 ? 'red' :
                                    record.ActivePlayerCount < 15 ? 'orange' :
                                        'success'}>
                                <span className="flex items-center gap-2">
                                    <span className="text-[1rem]">
                                        {record.ActivePlayerCount}
                                    </span>
                                    Players
                                </span>
                            </Tag>
                        </Tooltip>
                    ),
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.ActivePlayerCount - b.ActivePlayerCount,
                    searchValue: (record) => '' + record.ActivePlayerCount,
                    exportValue: (record) => '' + record.ActivePlayerCount,
                    requiredExtraAttributes: ['ActivePlayerCount'],
                    filterType: 'number',
                },
                'TotalPlayerCount': {
                    title: 'Total Players',
                    width: 130,
                    render: (record) => (
                        <Tooltip title={`${record.TotalPlayerCount} Player${record.TotalPlayerCount != 1 ? 's' : ''}`}>
                            <Tag>
                                <span className="flex items-center gap-2">
                                    <span className="text-[1rem]">
                                        {record.TotalPlayerCount}
                                    </span>
                                    Players
                                </span>
                            </Tag>
                        </Tooltip>
                    ),
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.TotalPlayerCount - b.TotalPlayerCount,
                    searchValue: (record) => '' + record.TotalPlayerCount,
                    exportValue: (record) => '' + record.TotalPlayerCount,
                    requiredExtraAttributes: ['TotalPlayerCount'],
                    filterType: 'number',
                },
            },
            defaultColumnName: 'NameWithLogo',
            defaultColumnsForRelations: [
                'NameWithLogoClickable',
                'nick',
                'short',
                'externalId',
                'foundDate',
                'status',
            ],
            initNew: (parentData, data) => {
                return {
                    ...data,
                    status: isAdmin ? 'ACTIVE' : 'DRAFT',
                    foundDate: dayjs(new Date()),
                }
            },
        },
        Player: {
            ...generateEntity("Player"),
            columns: {
                ...generateEntityColumns('Player', fields['Player']),
                'NameWithLogo': {
                    title: 'Name',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.lastName + ' ' + a.firstName).trim(), ('' + b.lastName + ' ' + b.firstName).trim()),
                    render: (record) =>
                        <span className="flex items-center gap-3 min-w-[150px]">
                            <span>
                                <EntityLogo entity={record} size={32} />
                            </span>
                            {record?.firstName + ' ' + record?.lastName}
                        </span>,
                    searchValue: (record) => '' + record.firstName + ' ' + record.lastName,
                    requiredAttributes: ['firstName', 'lastName', 'logoUrl'],
                },
                'NameWithLogoClickable': {
                    title: 'Name',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.lastName + ' ' + a.firstName).trim(), ('' + b.lastName + ' ' + b.firstName).trim()),
                    render: (record) =>
                        <a href={entities.Player.entityUrl(record)} target="_blank">
                            <span className="flex items-center gap-3 min-w-[150px]">
                                <span>
                                    <EntityLogo entity={record} size={32} />
                                </span>
                                {record?.firstName + ' ' + record?.lastName}
                            </span>
                        </a>,
                    searchValue: (record) => '' + record.firstName + ' ' + record.lastName,
                    requiredAttributes: ['firstName', 'lastName', 'logoUrl'],
                },
                'NameWithLogoPositionJersey': {
                    title: 'Player',
                    width: 300,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.lastName + ' ' + a.firstName).trim(), ('' + b.lastName + ' ' + b.firstName).trim()),
                    render: (record) =>
                        <span className="flex items-center gap-3 min-w-[150px]">
                            <span>
                                <EntityLogo entity={record} size={32} />
                            </span>
                            {record?.firstName + ' ' + record?.lastName}

                            {sorted(unique(record.ListPositions?.map(p => p.name))).map((p, idx) => (
                                <Tag key={idx} className="text-[0.85rem]">{p}</Tag>
                            ))}

                            {sorted(unique(record.Jerseys?.map(p => p.number))).map((p, idx) => (
                                <Tag key={idx} className="text-[0.85rem]">{p}</Tag>
                            ))}

                        </span>,
                    searchValue: (record) => '' + record.firstName + ' ' + record.lastName,
                    // not tested:
                    requiredAttributes: ['firstName', 'lastName'],
                    requiredRelations: ['ListPositions(name)', 'Jerseys(number)'],
                },
                'Level': {
                    title: 'Level',
                    render: (r) => r.ListPlayerLevel?.name ? <Tag className="whitespace-nowrap text-[0.85rem]">{r.ListPlayerLevel?.name}</Tag> : '',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.ListPlayerLevel?.name, b.ListPlayerLevel?.name),
                    searchValue: (r) => r.ListPlayerLevel?.name,
                    requiredRelations: ['ListPlayerLevel(name)'],
                    filterType: 'list',
                    filterValues: (r) => r.ListPlayerLevel?.name,
                },
                'LevelTag': {
                    title: 'Level',
                    align: 'center',
                    width: 75,
                    render: (r) => <PlayerLevelTag listPlayerLevel={r.ListPlayerLevel} />,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.ListPlayerLevel?.name, b.ListPlayerLevel?.name),
                    searchValue: (r) => r.ListPlayerLevel?.name,
                    exportValue: (r) => r.ListPlayerLevel?.code?.substr(0, 1)?.toUpperCase(),
                    requiredRelations: ['ListPlayerLevel(name,code)'],
                    filterType: 'list',
                    filterValue: (r) => r.ListPlayerLevel?.name,
                },
                'Teams': {
                    title: 'Teams',
                    render: (r) =>
                        <span className="flex items-center gap-3 flex-wrap">
                            {r.Teams?.map((t, idx) => (
                                <Tag key={idx} className="flex items-center gap-2 py-[2px] rounded-xl text-[0.85rem]">
                                    <EntityLogo entity={t} size={24} />
                                    {t.name}
                                </Tag>
                            ))}
                        </span>,
                    searchValue: (r) => r.Teams?.map(t => t.name).join(' '),
                    exportValue: (r) => r.Teams?.map(t => t.name).join(', '),
                    requiredRelations: ['Teams(name,logoUrl)'],
                    filterType: 'list',
                    filterValues: (r) => r.Teams?.map(t => t.name),
                },
                'RegistrationExistingTeam': {
                    title: 'Existing Team',
                    render: (r) => r.RegistrationExistingTeam &&
                        <span className="flex items-center gap-3 flex-wrap">
                            <Tag className="flex items-center gap-2 py-[2px] rounded-xl text-[0.85rem]">
                                <EntityLogo entity={r.RegistrationExistingTeam} size={24} />
                                {r.RegistrationExistingTeam?.name}
                            </Tag>
                        </span>,
                    searchValue: (r) => r.RegistrationExistingTeam?.name,
                    exportValue: (r) => r.RegistrationExistingTeam?.name,
                    requiredRelations: ['RegistrationExistingTeam(name,logoUrl)'],
                },
                'Positions': {
                    title: 'Positions',
                    width: 100,
                    render: (r) => <span className="flex flex-wrap gap-2">{sorted(unique(r.ListPositions?.map(p => p.name)))?.map((p, idx) => (
                        <Tag key={idx} className="text-[0.85rem]">{p}</Tag>
                    ))}</span>,
                    searchValue: (r) => r.ListPositions?.map(p => p.name),
                    exportValue: (r) => r.ListPositions?.map(p => p.name)?.join(', '),
                    requiredRelations: ['ListPositions(name)'],
                    filterType: 'list',
                    filterValues: (r) => r.ListPositions?.map(p => p.name),
                },
                'Jerseys': {
                    title: 'Jerseys',
                    width: 75,
                    render: (r) => (
                        <span className="flex gap-1">
                            {sorted(unique(r.Jerseys?.map(p => p.number)))?.map((p, idx) => (
                                <Tag key={idx} className="text-[0.85rem]">{p}</Tag>
                            ))}
                        </span>
                    ),
                    searchValue: (r) => r.Jerseys?.map(p => p.number),
                    exportValue: (r) => r.Jerseys?.map(p => p.number)?.join(', '),
                    requiredRelations: ['Jerseys(number)'],
                },
                'NewChangeRequestCount': {
                    title: 'Change Requests',
                    width: 150,
                    render: (r) => (
                        <span className="flex justify-center">
                            {r.NewChangeRequestCount && <Tag>{r.NewChangeRequestCount}</Tag>}
                        </span>
                    ),
                    requiredExtraAttributes: ['NewChangeRequestCount'],
                },
                'Status': {
                    title: 'Status',
                    width: 130,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.status), ('' + b.status)),
                    render: (record) => <HmsStatusTag status={record.status} />,
                    searchValue: (record) => '' + record.status,
                    exportValue: (record) => '' + getStatusText(record.status),
                    requiredAttributes: ['status'],
                    filterType: 'list',
                },
                'country': {
                    ...(generateEntityColumns('Player', fields['Player']).country),
                    filterType: 'list',
                },
                'countryBirth': {
                    ...(generateEntityColumns('Player', fields['Player']).countryBirth),
                    filterType: 'list',
                },
            },
            defaultValues: {
                status: isAdmin ? 'ACTIVE' : 'DRAFT',
                country: 'CZ',
                countryBirth: 'CZ',
                gender: 'MALE',
                stick: 'RIGHT',
            },
            defaultColumnName: 'NameWithLogo',
            defaultColumnsForRelations: [
                'NameWithLogoClickable',
                'LevelTag',
                'Teams',
                'Positions',
                'Jerseys',
                'status',
                'birthday',
                'phone',
                'email',
                'gender',
                'stick',
                'weight',
                'height',
                'externalId',
                'countryBirth',
                'cityBirth',
                'country',
                'city',
            ],
            getTitle: (record) => (record.firstName + ' ' + record.lastName),
        },
        Jersey: {
            ...generateEntity("Jersey"),
            columns: {
                ...generateEntityColumns('Jersey', fields['Jersey']),
                'TeamWithLogo': {
                    title: 'Team',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.Team?.name, b.Team?.name),
                    render: (record) =>
                        <span className="flex items-center gap-3">
                            <span>
                                <EntityLogo entity={record.Team} size={32} />
                            </span>
                            {record?.Team?.name}
                        </span>,
                    searchValue: (record) => record?.Team?.name ?? '',
                },
            },
            create: async (data) => {
                return await postEntity(entities.Jersey, data);
            },
            delete: async (jerseyId) => {
                return await deleteEntity(entities.Jersey, jerseyId);
            },
        },
        Person: {
            ...generateEntity("Person"),
            columns: {
                ...generateEntityColumns('Person', fields['Person']),
                'country': {
                    ...(generateEntityColumns('Person', fields['Person']).country),
                    filterType: 'list',
                },
                'countryBirth': {
                    ...(generateEntityColumns('Person', fields['Person']).countryBirth),
                    filterType: 'list',
                },
                'Name': {
                    title: 'Name',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.lastName + ' ' + a.firstName).trim(), ('' + b.lastName + ' ' + b.firstName).trim()),
                    render: (record) => record?.firstName + ' ' + record?.lastName,
                    requiredAttributes: ['firstName', 'lastName'],
                },
                'NewChangeRequestCount': {
                    title: 'Change Requests',
                    width: 150,
                    render: (r) => (
                        <span className="flex justify-center">
                            {r.NewChangeRequestCount && <Tag>{r.NewChangeRequestCount}</Tag>}
                        </span>
                    ),
                    requiredExtraAttributes: ['NewChangeRequestCount'],
                },            
                'Status': {
                    title: 'Status',
                    width: 130,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.status), ('' + b.status)),
                    render: (record) => <HmsStatusTag status={record.status} />,
                    searchValue: (record) => '' + record.status,
                    exportValue: (record) => '' + getStatusText(record.status),
                    requiredAttributes: ['status'],
                    filterType: 'list',
                },
                'Role': {
                    title: 'Role',
                    width: 150,
                    render: (r) => <span className="flex flex-wrap gap-2">{r.ListPersonRole?.name}</span>,
                    searchValue: (r) => r.ListPersonRole?.name,
                    exportValue: (r) => r.ListPersonRole?.name,
                    requiredRelations: ['ListPersonRole(name)'],
                    filterType: 'list',
                    filterValues: (r) => [r.ListPersonRole?.name],
                },
            },
            defaultColumnName: 'Name',
            defaultValues: {
                status: isAdmin ? 'ACTIVE' : 'DRAFT',
                country: 'CZ',
                countryBirth: 'CZ',
            },
            getTitle: (record) => (record.firstName + ' ' + record.lastName),
            defaultColumnsForRelations: [
                'Name',
                'birthday',
                'height',
                'weight',
                'phone',
                'email',
                'countryBirth',
                'cityBirth',
                'country',
                'city',
                'status',
                'externalId',
            ],
        },
        Game: {
            ...generateEntity("Game"),
            fetchRelations: ['HomeTeam', 'AwayTeam'],
            columns: {
                ...generateEntityColumns('Game', fields['Game']),
                'StartDateTime-nowrap': {
                    title: 'Start Date',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortDateAndTime(a.startDate, a.startTime, b.startDate, b.startTime),
                    render: (record) => (
                        <span className="whitespace-nowrap">{
                            '' +
                            formatDate(record.startDate, { withWeekDay: true }) +
                            (record.startDate && record.startDate != '' && record.startTime && record.startTime != '' ? ', ' : '') +
                            formatTime(record.startTime)
                        }</span>
                    ),
                    searchValue: (record) =>
                        '' +
                        formatDate(record.startDate, { withWeekDay: true }) +
                        (record.startDate && record.startDate != '' && record.startTime && record.startTime != '' ? ', ' : '') +
                        formatTime(record.startTime),
                    requiredAttributes: ['startDate', 'startTime'],
                    filterType: 'date',
                    filterValue: (r) => r.startDate,
                },
                'StartDateTime-weekday': {
                    title: 'Weekday',
                    width: 100,
                    render: (record) => formatWeekday(record.startDate),
                },
                'StartDateTime-dateonly': {
                    title: 'Date',
                    width: 125,
                    render: (record) => formatDate(record.startDate),
                },
                'StartDateTime-timeonly': {
                    title: 'Time',
                    width: 75,
                    render: (record) => formatTime(record.startTime),
                },
                'Venue': {
                    title: 'Venue',
                    sorter: (a, b) => sortString(a.Venue?.name, b.Venue?.name),
                    sortDirections: ['ascend'],
                    render: (r) => r?.Venue?.name,
                    searchValue: (r) => r?.Venue?.name,
                    requiredRelations: ['Venue(name)'],
                    filterType: 'list',
                },
                'HomeTeam-nick': {
                    title: 'Home Team',
                    sorter: (a, b) => sortString(a.HomeTeam?.nick, b.HomeTeam?.nick),
                    sortDirections: ['ascend'],
                    render: (record) =>
                        <span className="flex items-center justify-end gap-3 text-right">
                            {record?.HomeTeam?.nick}
                            <span>
                                <EntityLogo entity={record?.HomeTeam} size={32} />
                            </span>
                        </span>,
                    searchValue: (r) => r?.HomeTeam?.nick + ' ' + r?.HomeTeam?.name,
                    filterValues: (r) => [r?.HomeTeam?.nick],
                    requiredRelations: ['HomeTeam(nick,name,logoUrl)'],
                    filterType: 'list',
                },
                'AwayTeam-nick': {
                    title: 'Away Team',
                    sorter: (a, b) => sortString(a.AwayTeam?.nick, b.AwayTeam?.nick),
                    sortDirections: ['ascend'],
                    render: (record) =>
                        <span className="flex items-center justify-start gap-3">
                            <span>
                                <EntityLogo entity={record?.AwayTeam} size={32} />
                            </span>
                            {record?.AwayTeam?.nick}
                        </span>,
                    searchValue: (r) => r?.AwayTeam?.nick + ' ' + r?.AwayTeam?.name,
                    filterValues: (r) => [r?.HomeTeam?.nick],
                    requiredRelations: ['AwayTeam(nick,name,logoUrl)'],
                    filterType: 'list',
                },
                'TeamsVs-nick': {
                    title: 'Teams',
                    align: 'center',
                    width: 300,
                    render: (record) =>
                        <span className="flex items-center justify-center gap-2">
                            <span className="flex-1 flex justify-end items-center gap-2">
                                {record?.HomeTeam?.nick}
                                <span>
                                    <EntityLogo entity={record?.HomeTeam} size={32} />
                                </span>
                            </span>
                            <span className="text-gray-400 px-2">
                                vs.
                            </span>
                            <span className="flex-1 flex justify-start items-center gap-2">
                                <span>
                                    <EntityLogo entity={record?.AwayTeam} size={32} />
                                </span>
                                {record?.AwayTeam?.nick}
                            </span>
                        </span>,
                    exportValue: (record) => (record?.HomeTeam?.nick ?? '') + ' vs. ' + (record?.AwayTeam?.nick ?? ''),
                    requiredRelations: ['HomeTeam(nick,name,logoUrl)', 'AwayTeam(nick,name,logoUrl)'],
                },
                'Score': {
                    title: 'Score',
                    width: '5%',
                    render: (record) =>
                        <div>
                            <div className="font-bold font-mono whitespace-nowrap text-[1.2rem] flex justify-center">
                                {record?.HomeTeamGoals + ':' + record?.AwayTeamGoals}
                            </div>
                            {record.overwriteEnabled &&
                                <div className="flex justify-center">
                                    (kont.)
                                </div>
                            }
                        </div>,
                    exportExactCsv: true,   // prevent Excel from formatting score as time
                    requiredAttributes: ['overwriteEnabled'],
                    requiredExtraAttributes: ['HomeTeamGoals', 'AwayTeamGoals'],
                },
                'PaymentHost': {
                    title: 'Payment Host',
                    dataIndex: 'paymentHost',
                    width: '5%',
                    render: (value) => <PaymentTag value={value} />,
                    searchValue: (r) => r.paymentHost?.replace('NOT_PAID', 'NOT PAID'),
                    exportValue: (r) => r.paymentHost?.replace('NOT_PAID', 'NOT PAID'),
                    filterType: 'list',
                    filterListKeywords: { 'PAID': 'PAID', 'NOT_PAID': 'NOT PAID' },
                },
                'PaymentGuest': {
                    title: 'Payment Guest',
                    dataIndex: 'paymentGuest',
                    width: '5%',
                    render: (value) => <PaymentTag value={value} />,
                    searchValue: (r) => r.paymentGuest?.replace('NOT_PAID', 'NOT PAID'),
                    exportValue: (r) => r.paymentGuest?.replace('NOT_PAID', 'NOT PAID'),
                    filterType: 'list',
                    filterListKeywords: { 'PAID': 'PAID', 'NOT_PAID': 'NOT PAID' },
                },
                'PaymentReferree': {
                    title: 'Payment Referree',
                    dataIndex: 'paymentReferee',
                    width: '5%',
                    render: (value) => <PaymentTag value={value} />,
                    searchValue: (r) => r.paymentReferee?.replace('NOT_PAID', 'NOT PAID'),
                    exportValue: (r) => r.paymentReferee?.replace('NOT_PAID', 'NOT PAID'),
                    filterType: 'list',
                    filterListKeywords: { 'PAID': 'PAID', 'NOT_PAID': 'NOT PAID' },
                },
                'PaymentTimekeeper': {
                    title: 'Payment Timekeeper',
                    dataIndex: 'paymentTimekeeper',
                    width: '5%',
                    render: (value) => <PaymentTag value={value} />,
                    searchValue: (r) => r.paymentTimekeeper?.replace('NOT_PAID', 'NOT PAID'),
                    exportValue: (r) => r.paymentTimekeeper?.replace('NOT_PAID', 'NOT PAID'),
                    filterType: 'list',
                    filterListKeywords: { 'PAID': 'PAID', 'NOT_PAID': 'NOT PAID' },
                },
                'FlickrAlbumWithValidation': {
                    title: 'Flickr Album',
                    dataIndex: 'flickrAlbum',
                    width: '5%',
                    render: (value) => {
                        const isValid = validateFlickrAlbumUrl(value);
                        return (
                            <span className={`flex w-full gap-2 ${isValid ? 'text-[#6bc63e]' : 'text-[#c4521a]'}`}>
                                <span>
                                    {value != null && value != '' && isValid && <CheckCircleOutlined />}
                                    {value != null && value != '' && !isValid && <CloseCircleOutlined />}
                                </span>
                                <span className="break-all line-clamp-2 flex-wrap flex">
                                    {value}
                                </span>
                            </span>
                        );
                    },
                    searchValue: (r) => r.flickrAlbum,
                },
                'HostExternalId': {
                    title: 'Host External ID', 
                    render: (r) => r?.HomeTeam?.externalId, 
                    width: 150,
                    requiredRelations: ['HomeTeam(externalId)'],
                },
                'GuestExternalId': {
                    title: 'Guest External ID', 
                    render: (r) => r?.AwayTeam?.externalId, 
                    width: 150,
                    requiredRelations: ['AwayTeam(externalId)'],
                },
                'HomeTeamStar': {
                    title: 'Home Team Star',
                    width: 150,
                    render: (r) => r?.HomeTeamStars?.map(g => `${g?.Player?.firstName} ${g?.Player?.lastName}`).join(', '),
                    requiredRelations: ['HomeTeamStars>Player(firstName,lastName)']
                },
                'AwayTeamStar': {
                    title: 'Away Team Star',
                    width: 150,
                    render: (r) => r?.AwayTeamStars?.map(g => `${g?.Player?.firstName} ${g?.Player?.lastName}`).join(', '),
                    requiredRelations: ['AwayTeamStars>Player(firstName,lastName)']
                },
                'HomeTeamGoalie': {
                    title: 'Home Team Goalie',
                    width: 150,
                    render: (r) => r?.HomeTeamGoalies?.map(g => `${g?.Player?.firstName} ${g?.Player?.lastName} ${g?.number ? '(' + g.number + ')' : ''}`).join(', '),
                    requiredRelations: ['HomeTeamGoalies>Player(firstName,lastName)']
                },
                'AwayTeamGoalie': {
                    title: 'Away Team Goalie',
                    width: 150,
                    render: (r) => r?.AwayTeamGoalies?.map(g => `${g?.Player?.firstName} ${g?.Player?.lastName} ${g?.number ? '(' + g.number + ')' : ''}`).join(', '),
                    requiredRelations: ['AwayTeamGoalies>Player(firstName,lastName)']
                },
                'Phase': {
                    title: 'Phase', 
                    width: 150, 
                    render: (r) => r?.Phase?.name, 
                    requiredRelations: ['Phase(name)']
                },
                'Group': {
                    title: 'Group', 
                    width: 150, 
                    render: (r) => r?.Phase?.Group?.name, 
                    requiredRelations: ['Phase>Group(name)']
                },
                'Season': {
                    title: 'Season', 
                    width: 150, 
                    render: (r) => r?.Phase?.Group?.Season?.name, 
                    requiredRelations: ['Phase>Group>Season(name)']
                },
                'Competition': {
                    title: 'Competition', 
                    width: 150, 
                    render: (r) => r?.Phase?.Group?.Season?.Competition?.name, 
                    requiredRelations: ['Phase>Group>Season>Competition(name)']
                },
                'Headline': {
                    title: 'Headline', 
                    render: (r) => renderWithTooltipIfNeeded(r.headline), 
                    width: 150,
                    filterValue: (r) => r.headline,
                    searchValue: (r) => r.headline,
                    requiredAttributes: ['headline'],
                },
                'Perex': {
                    title: 'Perex', 
                    render: (r) => renderWithTooltipIfNeeded(r.perex), 
                    width: 150,
                    filterValue: (r) => r.perex,
                    searchValue: (r) => r.perex,
                    requiredAttributes: ['perex'],
                },
                'Body': {
                    title: 'Body', 
                    render: (r) => renderWithTooltipIfNeeded(r.body), 
                    width: 150,
                    filterValue: (r) => r.body,
                    searchValue: (r) => r.body,
                    requiredAttributes: ['body'],
                },
                'Info': {
                    title: 'Info', 
                    render: (r) => renderWithTooltipIfNeeded(r.info), 
                    width: 150,
                    filterValue: (r) => r.info,
                    searchValue: (r) => r.info,
                    requiredAttributes: ['info'],
                },
                'Description': {
                    title: 'Description', 
                    render: (r) => renderWithTooltipIfNeeded(r.description), 
                    width: 150,
                    filterValue: (r) => r.description,
                    searchValue: (r) => r.description,
                    requiredAttributes: ['description'],
                },
                'HomeTeamGoals': { 
                    width: 50, 
                    requiredExtraAttributes: ['HomeTeamGoals'], 
                    className: 'custom-no-padding', 
                    align: 'center', 
                    title: 'GH', 
                    titleTooltip: 'Home Team Goals',
                    render: (r) => suppressZero(r?.HomeTeamGoals),
                    filterType: 'number',
                    filterColumnTitle: 'GH (Home Team Goals)',
                },
                'AwayTeamGoals': { 
                    width: 50, 
                    requiredExtraAttributes: ['AwayTeamGoals'], 
                    className: 'custom-no-padding', 
                    align: 'center', 
                    title: 'GA',
                    titleTooltip: 'Away Team Goals',
                    render: (r) => suppressZero(r?.AwayTeamGoals),
                    filterType: 'number',
                    filterColumnTitle: 'GA (Away Team Goals)',
                },
                'HomeTeamPenalties': { 
                    width: 50, 
                    requiredExtraAttributes: ['HomeTeamPenalties'], 
                    className: 'custom-no-padding', 
                    align: 'center', 
                    title: 'PH',
                    titleTooltip: 'Home Team Penalties',
                    render: (r) => suppressZero(r?.HomeTeamPenalties),
                    filterType: 'number',
                    filterColumnTitle: 'PH (Home Team Penalties)',
                },
                'AwayTeamPenalties': { 
                    width: 50, 
                    requiredExtraAttributes: ['AwayTeamPenalties'], 
                    className: 'custom-no-padding', 
                    align: 'center', 
                    title: 'PA',
                    titleTooltip: 'Away Team Penalties',
                    render: (r) => suppressZero(r?.AwayTeamPenalties),
                    filterType: 'number',
                    filterColumnTitle: 'PA (Away Team Penalties)',
                },
                'HomeTeamSaves': { 
                    width: 50, 
                    requiredExtraAttributes: ['HomeTeamSaves'], 
                    className: 'custom-no-padding', 
                    align: 'center', 
                    title: 'SH',
                    titleTooltip: 'Home Team Saves',
                    render: (r) => suppressZero(r?.HomeTeamSaves),
                    filterType: 'number',
                    filterColumnTitle: 'SH (Home Team Saves)',
                },
                'AwayTeamSaves': { 
                    width: 50, 
                    requiredExtraAttributes: ['AwayTeamSaves'], 
                    className: 'custom-no-padding', 
                    align: 'center', 
                    title: 'SA',
                    titleTooltip: 'Away Team Saves',
                    render: (r) => suppressZero(r?.AwayTeamSaves),
                    filterType: 'number',
                    filterColumnTitle: 'SA (Away Team Saves)',
                },
                'HomeTeamFaceOffs': { 
                    width: 50, 
                    requiredExtraAttributes: ['HomeTeamFaceOffs'], 
                    className: 'custom-no-padding', 
                    align: 'center', 
                    title: 'FH',
                    titleTooltip: 'Home Team FaceOffs',
                    render: (r) => suppressZero(r?.HomeTeamFaceOffs),
                    filterType: 'number',
                    filterColumnTitle: 'FH (Home Team FaceOffs)',
                },
                'AwayTeamFaceOffs': { 
                    width: 50, 
                    requiredExtraAttributes: ['AwayTeamFaceOffs'], 
                    className: 'custom-no-padding', 
                    align: 'center', 
                    title: 'FA',
                    titleTooltip: 'Away Team FaceOffs',
                    render: (r) => suppressZero(r?.AwayTeamFaceOffs),
                    filterType: 'number',
                    filterColumnTitle: 'FA Away Team FaceOffs)',
                },
                'HomeTeamName': { 
                    title: 'Home Team Name', 
                    width: 200, render: (r) => r?.HomeTeam?.name 
                },
                'AwayTeamName': { 
                    title: 'Away Team Name', 
                    width: 200, 
                    render: (r) => r?.AwayTeam?.name 
                },
                'Open': {
                    title: 'Actions',
                    width: 10,
                    align: 'right',
                    render: (record) => (
                        <a href={`/games/${record.gameId}`} target="_blank">Open</a>
                    ),
                },
            },
            defaultColumnsForRelations: [
                { column: 'StartDateTime-nowrap', defaultSortOrder: 'descend' },
                'Venue', 
                'HomeTeam-nick',
                'Score',
                'AwayTeam-nick',
                'status', 
                'timekeeper', 
                'referee', 
                'foreignId', 
                'name',
            ],
            defaultColumnsForAddRelationsModal: [
                'StartDateTime-nowrap',
                'TeamsVs-nick',
                'Venue',
            ],
            clone: async (id) => {
                const clone = await cloneEntity(entities.Game, id, {
                    copyFields: [
                        'name',
                        'type',
                        'startDate',
                        'endDate',
                        'startTime',
                        'endTime',
                        'foreignId',
                        'info',
                        'description',
                        'timekeeper',
                        'referee',
                        'report',
                        'price',
                        'homeJerseyColor',
                        'awayJerseyColor',
                        'organizationId',
                        'phaseId',
                        'awayTeamId',
                        'homeTeamId',
                        'venueId',
                    ],
                    setFields: {
                        status: 'NOT PLAYED',
                    },
                });
                return {
                    status: clone.status,
                    url: '/games/' + clone.body?.gameId,
                }
            },
        },
        Disciplinary: {
            ...generateEntity("Disciplinary", { pluralName: 'Disciplinaries' }),
            defaultValues: {
                status: 'NEW',
                date: dayjs(new Date()),
            },
            columns: {
                ...generateEntityColumns('Disciplinary', fields['Disciplinary']),
                'Date': {
                    title: 'Date', 
                    width: 110, 
                    sortDirections: ['ascend', 'descend'],
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => sortString(('' + a.date), ('' + b.date)),
                    render: (record) => (
                        <span className="flex items-center gap-2">
                            {formatDate(record.date)}
                        </span>                        
                    ),
                    requiredAttributes: ['date'],
                },
                'Game': {
                    title: 'Game', 
                    width: 250,
                    sortDirections: ['ascend'],
                    sorter: (a, b) => sortString(
                        (`${a.Game?.startDate} ${formatTime(a.Game?.startTime)} ${a.Game?.HomeTeam?.short && a.Game?.HomeTeam?.short != '' && a.Game?.AwayTeam?.short && a.Game?.AwayTeam?.short != '' && a.Game?.HomeTeam?.short + ' vs ' + a.Game?.AwayTeam?.short} ${a.Game?.Venue?.name}`),
                        (`${b.Game?.startDate} ${formatTime(b.Game?.startTime)} ${b.Game?.HomeTeam?.short && b.Game?.HomeTeam?.short != '' && b.Game?.AwayTeam?.short && b.Game?.AwayTeam?.short != '' && b.Game?.HomeTeam?.short + ' vs ' + b.Game?.AwayTeam?.short} ${b.Game?.Venue?.name}`),
                    ),
                    render: (record) => (
                        record.Game ? (
                            <span className="flex items-center gap-2">
                                {record.Game?.startDate ? record.Game?.startDate : 'no date'} {formatTime(record.Game?.startTime)}
                                &nbsp;–&nbsp;
                                {record.Game?.HomeTeam?.short && record.Game?.HomeTeam?.short != '' && record.Game?.AwayTeam?.short && record.Game?.AwayTeam?.short != '' && record.Game?.HomeTeam?.short + ' vs ' + record.Game?.AwayTeam?.short}
                                &nbsp;–&nbsp;
                                {record.Game?.Venue?.name ?? 'no venue'}
                            </span>        
                        ) : 'no game'                
                    ),
                    requiredRelations: ['Game(startDate,startTime),Game>Venue(name),Game>HomeTeam(short),Game>AwayTeam(short)'],
                },
                'Competition': {
                    title: 'Competition', 
                    width: 200, 
                    sortDirections: ['ascend'],
                    sorter: (a, b) => sortString(('' + a.Competition?.name), ('' + b.Competition?.name)),
                    render: (record) => (
                        <span className="flex items-center gap-2">
                            {record.Competition?.name}
                        </span>                        
                    ),
                    requiredRelations: ['Competition(name)'],
                },
                'Players': {
                    title: 'Players', 
                    width: 150, 
                    render: (record) =>
                        record?.Players?.map((record, i) => (
                            <span key={i} className="flex items-center gap-3 min-w-[150px]">
                                <span>
                                    <EntityLogo entity={record} size={32} />
                                </span>
                                {record?.firstName + ' ' + record?.lastName}
                            </span>
                        )),
                    requiredRelations: ['Players(firstName,lastName,logoUrl)'],
                },
                'Status': {
                    title: 'Status',
                    width: 140,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.status), ('' + b.status)),
                    render: (record) => <Tag>{record.status}</Tag>,
                    searchValue: (record) => '' + record.status,
                    exportValue: (record) => '' + getStatusText(record.status),
                    requiredAttributes: ['status'],
                    filterType: 'list',
                },
            },
            defaultColumnsForRelations: [
                'Game',
                'subject',
                'status',
            ],
        },
        SeasonRegistration: {
            ...generateEntity("SeasonRegistration", { displayName: 'Registration', pluralName: 'Registrations', route: 'seasonregistrations' }),
            getTitle: (record) => 'Registration',
            defaultValues: {
            },
            columns: {
                ...generateEntityColumns('SeasonRegistration', fields['SeasonRegistration']),
                'CreatedAt': {
                    title: 'Created At',
                    width: 170,
                    render: (record) => formatDateTime(record.createdAt),
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.createdAt, b.createdAt),
                    defaultSortOrder: 'descend',
                    requiredAttributes: ['createdAt'],
                },
                'Team.NameWithLogo': {
                    title: 'Team',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.Team?.name, b.Team?.name),
                    render: (record) =>
                        <span className="flex items-center gap-3">
                            <span>
                                <EntityLogo entity={record?.Team} size={32} />
                            </span>
                            {record?.Team?.name}
                        </span>,
                    searchValue: (record) => record?.Team?.name ?? '',
                    requiredRelations: ['Team(name,logoUrl)'],
                },
                'Competition.NameWithLogo': {
                    title: 'Competition',
                    width: 150,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.Season?.Competition?.name, b?.Season?.Competition?.name),
                    render: (record) =>
                        <span className="flex items-center gap-3">
                            <span>
                                <EntityLogo entity={record?.Season?.Competition} size={32} />
                            </span>
                            {record?.Season?.Competition?.name}
                        </span>,
                    requiredRelations: ['Season>Competition(name,logoUrl)'],
                },
                'Season.Name': {
                    title: 'Season',
                    width: 125,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a?.Season?.name, b?.Season?.name),
                    render: (record) => record?.Season?.name,
                    requiredRelations: ['Season(name)'],
                },
                'PreferredGroups': {
                    title: 'Preferred Groups',
                    width: 140,
                    render: (record) => {
                        return record?.Groups?.map(g => g.name)?.join(', ');
                    },
                    requiredRelations: ['Groups(name)'],
                },
                'Status': {
                    title: 'Status',
                    width: 180,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.status), ('' + b.status)),
                    render: (record) => <HmsStatusTag status={record.status} />,
                    searchValue: (record) => '' + record.status,
                    exportValue: (record) => '' + getStatusText(record.status),
                    requiredAttributes: ['status'],
                    filterType: 'list',
                },
                'Specials': {
                    title: 'Special Requests',
                    width: 200,
                    render: (record) => (
                        <span className="flex flex-col gap-2">     
                            {record?.SeasonRegistrationSpecials?.map((special, idx) => (
                                <span key={idx} className="flex items-center gap-2">
                                    <DollarCircleOutlined />
                                    <Tooltip title={<div className="whitespace-pre-wrap">{special.text}</div>}>
                                        {special.name}
                                    </Tooltip>
                                </span>
                            ))}
                            {record?.SeasonRegistrationSpecials?.length == 0 && <span className="text-gray-400">-</span>}
                        </span>
                    ),
                    requiredRelations: ['SeasonRegistrationSpecials(name,text)'],
                },
                'RegisteredStatus': {
                    title: 'Actual Membership',
                    width: 165,
                    render: (record) => {
                        const registeredGroups = record.Team?.Groups?.filter(g => g.Season?.seasonId == record.seasonId)?.map(g => g.name) ?? [];
                        return (
                            <>
                                {registeredGroups.length > 0
                                    ?
                                        <span className="text-green-500"><CheckCircleFilled /> Registered: <span className="text-black">{registeredGroups.join(', ')}</span></span>
                                    :
                                        <span className="text-orange-500"><WarningFilled /> Not registered</span>
                                }
                            </>
                        )
                    },
                    requiredAttributes: 'seasonId',
                    requiredRelations: ['Team>Groups>Season(seasonId)'],
                },
                'ExternalComment': {
                    title: 'External Comment',
                    width: 200,
                    render: (r) => renderWithTooltipIfNeeded(r.externalComment), 
                    requiredAttributes: 'externalComment',
                },
                'InternalComment': {
                    title: 'Internal Comment',
                    width: 200,
                    render: (r) => renderWithTooltipIfNeeded(r.internalComment), 
                    requiredAttributes: 'internalComment',
                },
            },
            defaultColumnsForRelations: [
                'Team.NameWithLogo',
                'PreferredGroups',
                'Specials',
                'Status',
                'RegisteredGroup'
            ],
        },
        ChangeRequest: {
            ...generateEntity("ChangeRequest"),
            getTitle: (record) => 'Change Request',
            defaultValues: {
            },
            columns: {
                ...generateEntityColumns('ChangeRequest', fields['ChangeRequest']),
                'CreatedAt': {
                    title: 'Created At',
                    width: 170,
                    render: (record) => formatDateTime(record.createdAt),
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.createdAt, b.createdAt),
                    defaultSortOrder: 'descend',
                    requiredAttributes: ['createdAt'],
                },
                'CreatedBy': {
                    title: 'Created By',
                    render: (record) => formatCreatedBy(record.createdBy),
                    requiredAttributes: ['createdBy'],
                },
                'UpdatedAt': {
                    title: 'Processed At',
                    render: (record) => record.status != 'NEW' ? formatDateTime(record.updatedAt) : '',
                    requiredAttributes: ['createdAt', 'status'],
                },
                'UpdatedBy': {
                    title: 'Processed By',
                    render: (record) => record.status != 'NEW' ? formatCreatedBy(record.createdBy) : '',
                    requiredAttributes: ['createdBy', 'status'],
                },
                'FieldDisplayName': {
                    title: 'Field',
                    render: (record) => <span className="font-bold">{record.fieldDisplayName}</span>,
                    requiredAttributes: ['fieldDisplayName'],
                },
                'OldDisplayValue': {
                    title: 'Current Value',
                    render: (record) => <span className="line-through">{record.oldDisplayValue}</span>,
                    requiredAttributes: ['oldDisplayValue'],
                },
                'NewDisplayValue': {
                    title: 'New Value',
                    render: (record) => <span className="font-bold">{record.newDisplayValue}</span>,
                    requiredAttributes: ['newDisplayValue'],
                },
                'Status': {
                    title: 'Status',
                    width: 50,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(('' + a.status), ('' + b.status)),
                    render: (record) => <HmsStatusTag status={record.status} />,
                    searchValue: (record) => '' + record.status,
                    exportValue: (record) => '' + getStatusText(record.status),
                    requiredAttributes: ['status'],
                    filterType: 'list',
                },
            },
        },
        PasswordResetRequest: {
            ...generateEntity("PasswordResetRequest"),
            getTitle: (record) => 'PasswordResetRequest',
            defaultValues: {
            },
            columns: {
                ...generateEntityColumns('PasswordResetRequest', fields['PasswordResetRequest']),
                'CreatedAt': {
                    title: 'Sent At',
                    width: 100,
                    render: (record) => formatDateTime(record.createdAt),
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.createdAt, b.createdAt),
                    defaultSortOrder: 'descend',
                    requiredAttributes: ['createdAt'],
                },
                'CreatedBy': {
                    title: 'Sent By',
                    width: 100,
                    render: (record) => formatCreatedBy(record.createdBy),
                    requiredAttributes: ['createdBy'],
                },
                'UsedAt': {
                    title: 'Used At',
                    width: 100,
                    render: (record) => record.usedAt ? formatDateTime(record.usedAt) : 'never',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.usedAt, b.usedAt),
                    defaultSortOrder: 'descend',
                    requiredAttributes: ['usedAt'],
                },
                'Status': {
                    title: 'Status',
                    width: 200,
                    render: (record) => {
                        const status =
                            record.usedAt ? 'USED' :
                            moment(record.expiration).isBefore(moment()) ? 'EXPIRED' :
                            'ACTIVE';
                        const color =
                            status === 'USED' ? 'green' :
                            status === 'EXPIRED' ? 'red' :
                            'blue';

                        return (<Tag color={color}>{status}</Tag>);
                    },
                    requiredAttributes: ['expiration'],
                },
            },
            defaultColumnsForRelations: [
                'CreatedAt',
                'CreatedBy',
                'Status',
            ],
        },
        GameEventGoal: {
            ...generateEntity("GameEventGoal"),
        },
        GameEventSave: {
            ...generateEntity("GameEventSave"),
        },
        GameEventPenalty: {
            ...generateEntity("GameEventPenalty"),
        },
        GameEventPenaltyshot: {
            ...generateEntity("GameEventPenaltyshot"),
        },
        GameEventFaceOff: {
            ...generateEntity("GameEventFaceOff"),
        },
        GameEventInjury: {
            ...generateEntity("GameEventInjury"),
        },
        SchedulerSimulation: {
            ...generateEntity("SchedulerSimulation", { urlPrefix: '/schedulers' }),
            entityUrl: (data) => `/schedulersimulations/${data.schedulerSimulationId}`,
            newEntityUrl: () => null,
            originalLink: null,
            columns: {
                ...generateEntityColumns('SchedulerSimulation', fields['SchedulerSimulation']),
                'CreatedAt': {
                    title: 'Created At',
                    width: 170,
                    render: (record) => formatDateTime(record.createdAt),
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.createdAt, b.createdAt),
                    defaultSortOrder: 'descend',
                    requiredAttributes: ['createdAt'],
                },
                'Result': {
                    title: 'Result (Preference rate %)',
                    width: 250,
                    render: (record) => {
                        function getPercentClass(percent) {
                            let className;
                            if (percent == 100) {
                                className = 'text-green-500';
                            } else if (percent > 75) {
                                className = 'text-orange-500';
                            } else {
                                className = 'text-red-500';
                            }
                            return className;
                        }

                        return (
                            <div className="flex gap-3">
                                <span className="flex items-center gap-1 text-green-500 whitespace-nowrap">{icons.Game} {record.gamesCount} game(s)</span>
                                {record.warningCount > 0 &&
                                    <span className="flex items-center gap-1 text-orange-500 whitespace-nowrap">{icons.ErrorTriangle} {record.warningCount} warning(s)</span>
                                }
                                {record.errCount > 0 &&
                                    <span className="flex items-center gap-1 text-red-500 whitespace-nowrap">{icons.ErrorTriangle} {record.errCount} error(s)</span>
                                }
                                {record.teamPrefRateMin &&
                                    <span className="text-gray-500">
                                        (
                                            <span className={getPercentClass(record.teamPrefRateMin)}>{record.teamPrefRateMin}%</span>
                                            -
                                            <span className={getPercentClass(record.teamPrefRateMax)}>{record.teamPrefRateMax}%</span>
                                        )
                                    </span>
                                }
                            </div>
                        )
                    },
                    requiredAttributes: ['gamesCount', 'warningCount', 'errCount', 'teamPrefRateMin', 'teamPrefRateMax'],
                },
                'GamesCount-Hidden': {
                    title: 'Games Count',
                    render: (record) => record.gamesCount,
                    visible: false,
                    exported: false,
                    filterType: 'number',
                    filterValue: (r) => r.gamesCount,
                    requiredAttributes: ['gamesCount'],
                },
                'WarningCount-Hidden': {
                    title: 'Warning Count',
                    render: (record) => record.gamesCount,
                    visible: false,
                    exported: false,
                    filterType: 'number',
                    filterValue: (r) => r.warningCount,
                    requiredAttributes: ['warningCount'],
                },
                'ErrorCount-Hidden': {
                    title: 'Error Count',
                    render: (record) => record.gamesCount,
                    visible: false,
                    exported: false,
                    filterType: 'number',
                    filterValue: (r) => r.errCount,
                    requiredAttributes: ['errCount'],
                },
                'GeneratedGamesCount': {
                    title: 
                        <Tooltip title="Number of real games generated">
                            Generated Games *
                        </Tooltip>,
                    width: 80,
                    align: 'right',
                    render: (record) => {
                        const l = record.GeneratedGamesCount;
                        if (l > 0) {
                            return <span className="text-orange-500">{l}</span>
                        } else {
                            return <span className="text-gray-400">{l}</span>
                        }
                    },
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.GeneratedGamesCount - b.GeneratedGamesCount,
                    filterType: 'number',
                    requiredExtraAttributes: ['GeneratedGamesCount'],
                },
            },
            defaultColumnsForRelations: [
                'CreatedAt',
                'algorithm',
                'Result',
                'GamesCount-Hidden',
                'WarningCount-Hidden',
                'ErrorCount-Hidden',
                'GeneratedGamesCount',
            ],
        },
        SchedulerConfig: {
            ...generateEntity("SchedulerConfig"),
            defaultValues: {
                iterations: 1,
                status: 'NEW',
            },
            columns: {
                ...generateEntityColumns('SchedulerConfig', fields['SchedulerConfig']),
                'Groups': {
                    title: 'Groups',
                    render: (record) => (
                        <div>
                            {record?.Phases?.map((p, idx) => (<div>
                                {'' + (idx+1) + '. ' + [
                                    p.Group?.Season?.Competition?.name,
                                    p.Group?.Season?.name,
                                    p.Group?.name,
                                    p.name,
                                ].join(' → ')}
                            </div>))}
                        </div>
                    ),
                    searchValue: (record) =>
                        record?.Phases?.map(p => [
                                p.Group?.Season?.Competition?.name,
                                p.Group?.Season?.name,
                                p.Group?.name,
                                p.name,
                            ].join(' → ')).join(' '),
                    requiredRelations: ['Phases(name),Phases>Group(name),Phases>Group>Season(name),Phases>Group>Season>Competition(name)'],
                },
                'Venues': {
                    title: 'Venues',
                    render: (record) => (
                        <div>
                            {record?.Venues?.map((v, idx) => (<div>{'' + (idx+1) + '. ' + v.name}</div>))}
                        </div>
                    ),
                    searchValue: (record) =>record?.Venues?.map(v => v.name)?.join(' '),
                    requiredRelations: ['Venues(name)'],
                },
                'Competition': {
                    title: 'Competition',
                    render: (record) => unique(record?.Phases?.map(p => p.Group?.Season?.Competition?.name)).join(', '),
                    searchValue: (record) => unique(record?.Phases?.map(p => p.Group?.Season?.Competition?.name)).join(', '),
                    requiredRelations: ['Phases>Group>Season>Competition(name)'],
                },
                'Season': {
                    title: 'Season',
                    render: (record) => unique(record?.Phases?.map(p => p.Group?.Season?.name)).join(', '),
                    searchValue: (record) => unique(record?.Phases?.map(p => p.Group?.Season?.name)).join(', '),
                    requiredRelations: ['Phases>Group>Season(name)'],
                },
                'GroupCount': {
                    title: 'Groups',
                    align: 'right',
                    render: (record) => unique(record?.Phases?.map(p => p.Group?.name))?.length ?? 0,
                    filterType: 'number',
                    requiredRelations: ['Phases>Group(groupId)'],
                },
                'TeamCount': {
                    title: 'Teams',
                    align: 'right',
                    render: (record) => record?.TeamCount,
                    filterType: 'number',
                    requiredExtraAttributes: ['TeamCount'],
                },
                'VenueCount': {
                    title: 'Venues',
                    align: 'right',
                    render: (record) => record?.VenueCount,
                    filterType: 'number',
                    requiredExtraAttributes: ['VenueCount'],
                },
                'SchedulerSimulationCount': {
                    title: 'Simulations',
                    align: 'right',
                    width: 130,
                    render: (record) => {
                        const c = record?.SchedulerSimulationCount;
                        if (c > 0) {
                            return <span className="text-black">{c}</span>
                        } else {
                            return <span className="text-gray-400">{c}</span>
                        }
                    },
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.SchedulerSimulationCount - b.SchedulerSimulationCount,
                    filterType: 'number',
                    requiredExtraAttributes: ['SchedulerSimulationCount'],
                },
                'GeneratedGameCount': {
                    title: 
                        <Tooltip title="Number of real games generated">
                            Games *
                        </Tooltip>,
                    align: 'right',
                    width: 120,
                    render: (record) => {
                        const c = record?.GeneratedGamesCount;
                        if (c > 0) {
                            return <span className="text-orange-500">{c}</span>;
                        } else {
                            return <span className="text-gray-400">{c}</span>;
                        }
                    },
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.GeneratedGamesCount - b.GeneratedGamesCount,
                    filterType: 'number',
                    requiredExtraAttributes: ['GeneratedGamesCount'],
                },
            },
            clone: async (id) => {
                const clone = await cloneEntity(entities.SchedulerConfig, id, {
                    setFields: {
                        status: 'NEW',
                    },
                    copyFields: [
                        'name',
                        'iterations',
                    ],
                    linkAssociations: [
                        'Venues',
                        'Phases',
                    ],
                    copyAssociations: [
                        'SchedulerConfigIcePatterns',
                        'SchedulerConfigSpecialRequests',
                    ],
                });
                return {
                    status: clone.status,
                    url: '/schedulerconfigs/' + clone.body?.schedulerConfigId,
                }
            },

            // ...generateEntity("SchedulerConfig", { urlPrefix: '/scheduler_configs' }),
            // entityUrl: (data) => `/scheduler_configs/${data.schedulerConfigId}`,
            // // newEntityUrl: () => null,
            // originalLink: null,
        },
        SchedulerConfigIce: {
            ...generateEntity("SchedulerConfigIce"),
            columns: {
                ...generateEntityColumns('SchedulerConfigIce', fields['SchedulerConfigIce']),
                'Venue': {
                    title: 'Venue',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.Venue?.name, b.Venue?.name),
                    render: (record) => record?.Venue?.name,
                    searchValue: (record) => record?.Venue?.name ?? '',
                    requiredRelations: ['Venue(name)'],
                },
                'Date': {
                    title: 'Date',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.startDateTime, b.startDateTime),
                    render: (record) => moment(record?.startDateTime).format('YYYY-MM-DD, ddd'),
                    searchValue: (record) => moment(record?.startDateTime).format('YYYY-MM-DD, ddd'),
                },
                'StartEnd': {
                    title: 'Start - End',
                    width: 150,
                    // sortDirections: ['ascend', 'descend'],
                    // sorter: (a, b) => sortString(a.startDateTime, b.startDateTime),
                    render: (record) => [moment(record?.startDateTime).format('HH:mm'), moment(record?.endDateTime).format('HH:mm')].join(' - '),
                    searchValue: (record) => [moment(record?.startDateTime).format('HH:mm'), moment(record?.endDateTime).format('HH:mm')].join(' - '),
                },
            },
            defaultColumnsForRelations: [
                'Date',
                'StartEnd',
                'Venue',
                'category',
            ],
        },
        SchedulerConfigIcePattern: {
            ...generateEntity("SchedulerConfigIcePattern"),
            columns: {
                ...generateEntityColumns('SchedulerConfigIcePattern', fields['SchedulerConfigIcePattern']),
                'Venue': {
                    title: 'Venue',
                    width: 200,
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => sortString(a.Venue?.name, b.Venue?.name),
                    render: (record) => record?.Venue?.name,
                    searchValue: (record) => record?.Venue?.name ?? '',
                    requiredRelations: ['Venue(name)'],
                },
                'Weekday': {
                    title: 'Weekday',
                    render: (record) => record?.weekday,
                    sorter: (a, b) => {
                        const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                        const valA = weekdays.indexOf(a.weekday);
                        const valB = weekdays.indexOf(b.weekday);
                        return valA - valB;
                    },
                },
                'Except': {
                    title: 'Except',
                    render: (record) => <div className="flex flex-col gap-1">{(record?.except ?? '').split(',').map((e, idx) => <span key={idx} className="whitespace-nowrap">{e}</span>)}</div>,
                },
            },
            defaultColumnsForRelations: [
                'Venue',
                'startDate',
                'endDate',
                'Weekday',
                'startTime',
                'endTime',
                'Except',
                'category',
            ],
        },
        SchedulerConfigSpecialRequest: {
            ...generateEntity("SchedulerConfigSpecialRequest"),
            columns: {
                ...generateEntityColumns('SchedulerConfigSpecialRequest', fields['SchedulerConfigSpecialRequest']),
                'Team': {
                    title: 'Team',
                    render: (record) => record?.Team?.name,
                    requiredRelations: ['Team(name)']
                },
                // 'Location': {
                //     title: 'Location',
                //     render: (record) => record?.location,
                // },
                'IcePattern': {
                    title: 'Ice Pattern',
                    render: (record) =>
                        record?.SchedulerConfigIcePattern?.Venue?.name + ', ' + 
                        record?.SchedulerConfigIcePattern?.weekday + ', ' +
                        formatTimeShort(record.SchedulerConfigIcePattern?.startTime) + ' – ' + 
                        formatTimeShort(record.SchedulerConfigIcePattern?.endTime),
                },
            },
            defaultColumnsForRelations: [
                'Team',
                'type',
                'location',
                'IcePattern',
            ],
        },
        Lineup: {
            ...generateEntity("Lineup"),
        },
        GameStar: {
            ...generateEntity("GameStar"),
        },
        ListColor: {
            ...generateRulePack('ListColor'),
        },
        ListEventLocation: {
            ...generateRulePack('ListEventLocation'),
        },
        ListGoalLocation: {
            ...generateRulePack('ListGoalLocation'),
        },
        ListGoalType: {
            ...generateRulePack('ListGoalType'),
        },
        ListGoalSubtype: {
            ...generateRulePack('ListGoalSubtype'),
            columns: {
                ...(generateRulePack('ListGoalSubtype').columns),
                'Parent': { title: 'Parent Goal Type', render: (r) => r?.ListGoalType?.name },
            },
            defaultColumns: [
                ...(generateRulePack('ListGoalSubtype').defaultColumns),
                'Parent',
            ],
            fetchRelations: 'ListGoalType',
        },
        ListInjuryType: {
            ...generateRulePack('ListInjuryType'),
        },
        ListPenaltyType: {
            ...generateRulePack('ListPenaltyType'),
        },
        ListPenaltySubtype: {
            ...generateRulePack('ListPenaltySubtype'),
            columns: {
                ...(generateRulePack('ListPenaltySubtype').columns),
                'Parent': { title: 'Parent Penalty Type', render: (r) => r?.ListPenaltyType?.name },
            },
            defaultColumns: [
                ...(generateRulePack('ListPenaltySubtype').defaultColumns),
                'Parent',
            ],
            fetchRelations: 'ListPenaltyType',
        },
        ListPeriod: {
            ...generateRulePack('ListPeriod'),
        },
        ListPlayerLevel: {
            ...generateRulePack('ListPlayerLevel'),
        },
        ListPersonRole: {
            ...generateRulePack('ListPersonRole'),
        },
        ListPosition: {
            ...generateRulePack('ListPosition'),
        },
        ListResultPoint: {
            ...generateRulePack('ListResultPoint'),
        },
        ListResultType: {
            ...generateRulePack('ListResultType'),
        },
        ListShotType: {
            ...generateRulePack('ListShotType'),
        },
    };

    function generateEntity(name, options = {}) {
        if (!fields[name]) {
            throw new Error(`Entity does not exist: ${name}`);
        }

        const root = name;
        const pluralName = options.pluralName ?? (root + 's');
        const displayName = options.displayName ?? stringToTitleCase(root);
        const displayNamePlural = options.displayNamePlural ?? stringToTitleCase(pluralName);

        const routePrefix = (options.urlPrefix ? options.urlPrefix.substr(1) + '/' : '') + (options.route ?? (pluralName.toLowerCase() + (!isAdmin ? '_team_manager' : '')));

        if (!options.canDelete) {
            options.canDelete = () => isAdmin;
        }

        const newUrl = '/' + routePrefix + '/_new_';
        const primaryKey = root[0].toLowerCase() + root.substring(1) + 'Id';
        const defaultColumns = Object.keys(generateEntityColumns(name, fields[name]));
        const defaultColumnName = defaultColumns[0];
        const hasLogo = fields[name]['logoUrl'] !== undefined;
        const entityStatic = {
            name,
            displayName,
            displayNamePlural,
            routePrefix,
            newUrl,
            primaryKey,
            fields: fields[name],
            defaultColumns,
            defaultColumnName,
            hasLogo,
        }
        return {
            ...entityStatic,
            ...generateCallbacks(entityStatic, options),
            getTitle: (record) => record?.subject ?? record?.name ?? 'Untitled',
            defaultColumnName: 'name',
            columns: generateEntityColumns(name, fields[name]),
            entityBreadcrumb: (data) => [
                { title: 'Home', icon: icons.Home, link: '/' },
                { title: displayNamePlural, icon: icons[name], link: '/' + routePrefix },
                { title:
                    data && data[primaryKey] && data.isCloneDraft ? 'New (clone)' :
                    data && data[primaryKey] ? (entities[name].getTitle ? entities[name].getTitle(data) : data.name) :
                    'New',
                    icon: icons[name]
                },
            ],
            entitiesBreadcrumb: [
                { title: 'Home', icon: icons.Home, link: '/' },
                { title: displayNamePlural, icon: icons[name] },
            ],
            newEntityUrl: () => newUrl,
            originalLink: (data) => data && data[primaryKey] ? `https://hockey-management-system.netlify.app/admin/${name.toLowerCase()}/phm/${data[primaryKey].toLowerCase()}` : null,
            linkWith: async (sourceEntityId, targetEntityName, targetEntityId, targetEntityAs) => {
                const response = await postRelation(entities[name], sourceEntityId, targetEntityName, targetEntityId, targetEntityAs);
                if (response.status != 200) {
                    addMessage('There was an error creating relations.');
                }
                return response;
            },
            removeLink: async (sourceEntityId, targetEntityName, targetEntityId, targetEntityAs) => {
                const response = await deleteRelation(entities[name], sourceEntityId, targetEntityName, targetEntityId, targetEntityAs);
                if (response.status != 200) {
                    addMessage('There was an error deleting relations.');
                }
                return response;
            },
            create: async (data) => {
                const response = await postEntity(entityStatic, data);
                if (response.status != 200) {
                    addMessage('There was an error creating an entity.');
                }
                return response;
            },
            bulkCreate: async (data) => {
                const response = await postEntities(entityStatic, data);
                if (response.status != 200) {
                    addMessage('There was an error creating entities.');
                }
                return response;
            },
            bulkDelete: async (ids) => {
                const response = await deleteEntities(entityStatic, ids);
                if (response.status != 200) {
                    addMessage('There was an error deleting entities.');
                }
                return response;
            },
            patch: async (id, data) => {
                const response = await patchEntity(entityStatic, id, data);
                if (response.status != 200) {
                    addMessage('There was an error updating entity.');
                }
                return response;
            },
            // delete: async (idOrData) => {
            //     const id = typeof idOrData == 'object' ? idOrData[primaryKey] : idOrData;
            //     return await deleteEntity(entityStatic, id);
            // },
            put: async (data) => {
                const response = await putEntity(entityStatic, data);
                if (response.status != 200) {
                    addMessage('There was an error updating entity.');
                }
                return response;
            }
        };
    }

    function generateRulePack(name) {
        const root = name.replace('List', '');
        const displayName = stringToTitleCase(root);
        const namePlural = stringToTitleCase(root);
        const displayNamePlural = stringToTitleCase(root) + 's';
        const newUrl = '/rulepacks/' + name.toLowerCase() + 's' + '/_new_';
        const routePrefix = 'rulepacks/' + name.toLowerCase() + 's';
        const primaryKey = 'list' + root + 'Id';
        const columns = Object.fromEntries(
            Object.entries(
                generateEntityColumns(root, fields[name])
            )
                .filter(([key, value]) => !(key.startsWith('list') && key.endsWith('Id')))
                .map(([key, value]) => {
                    if (key == 'priority') {
                        return [key, {
                            ...value,
                            sortDirections: ['ascend', 'descend'],
                            sorter: (a, b) => (a.priority ?? 9999 - b.priority ?? 9999),
                            defaultSortOrder: 'ascend',
                            width: 50,
                        }];
                    } else {
                        return [key, value];
                    }
                })
        );
        const defaultColumns = [
            ...(Object.keys(generateEntityColumns(name, fields[name])).filter(c => c == 'priority')),
            ...(Object.keys(generateEntityColumns(name, fields[name])).filter(c => c != 'priority')),
        ];
        const defaultColumnName = defaultColumns.find(c => c != 'priority');
        const entityStatic = {
            name,
            displayName,
            displayNamePlural,
            namePlural,
            newUrl,
            routePrefix,
            primaryKey,
            columns,
            defaultColumns,
            defaultColumnName,
            fields: fields[name],
        }
        return {
            ...entityStatic,
            ...generateCallbacks(entityStatic),
            entityBreadcrumb: (data) => [
                { title: 'Rule Packs', icon: icons.Settings, link: '/rulepacks' },
                { title: namePlural, icon: null, link: '/' + routePrefix },
                { title: data && data[primaryKey] ? (entities[name].getTitle ? entities[name].getTitle(data) : data.name) : 'New', icon: icons[name] },
            ],
            entitiesBreadcrumb: [
                { title: 'Rule Packs', icon: icons.Settings, link: '/rulepacks' },
                { title: namePlural, icon: null },
            ],
        };
    }

    function generateCallbacks(entityStatic, options) {
        return {
            entityUrl: (data) => data && `/${entityStatic.routePrefix}/${data[entityStatic.primaryKey]}`,
            entitiesUrl: () => `/${entityStatic.routePrefix}`,
            canDelete: (data) => (data != null && data[entityStatic.primaryKey] != null) && (options?.canDelete?.() ?? true),
            canClone: (data) => (data != null && data[entityStatic.primaryKey] != null && !data.isCloneDraft),
            save: async (data) => await saveEntityHandler(entityStatic, data),
            delete: async (idOrData) => await deleteEntityHandler(entityStatic, idOrData),
        };
    }

    async function saveEntityHandler(entity, data) {
        const response = data[entity.primaryKey] ? await putEntity(entity, data) : await postEntity(entity, data);

        if (response.status == 200 && response.body[entity.primaryKey]) {
            return {
                success: true,
                data: response.body,
            };
        } else {
            addMessage('There was an error saving this entity.');
            return {
                success: false,
            };
        }
    };

    async function deleteEntityHandler(entity, idOrData) {
        const id = typeof idOrData == 'object' ? idOrData[entity.primaryKey] : idOrData;
        const response = await deleteEntity(entity, id);

        if (response.status == 200) {
            return {
                success: true,
            };
        } else {
            addMessage('There was an error deleting this entity.');
            return {
                success: false,
            };
        };
    };

    return entities;

}

export default useEntities;