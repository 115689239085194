import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';

const TeamPersons = ({ sourceEntityId, canEdit, data, onChange }) => {
    const entities = useEntities();
    const sourceEntity = entities.Team;
    const targetEntity = entities.Player;

    return (
        <RelationsEditable 
            data={data}
            sourceEntity={sourceEntity} 
            targetEntity={targetEntity}
            targetEntityColumns={entityColumnsToAntColumns(targetEntity, [
                { column: 'NameWithLogo', width: 200, },
                { column: 'status', width: 50 },
            ])}
            sourceEntityId={sourceEntityId}
            emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            onChange={onChange}
            canRemove={canEdit}
            canAdd={canEdit}
        />
    )
}

export default TeamPersons
