import EmbedGamesInner from 'components/Embeds/EmbedGamesInner';
import useFetchData from 'hooks/useFetchData'
import useLang from 'hooks/useLang';
import usePageTitle from 'hooks/usePageTitle';
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import EmbedWrapper from 'components/Embeds/EmbedWrapper';

const EmbedGames = () => {
  const [searchParams] = useSearchParams();
  const seasonIds = searchParams.get('seasonIds');
  const seasonId = searchParams.get('seasonId');
  const teamId = searchParams.get('teamId');
  const lang = searchParams.get('lang');
  const LANG = useLang(lang);
  usePageTitle(LANG.EMBED_GAMES_TITLE)

  const { data, isLoading, isError } = useFetchData(`/public/games` +
    `?seasonIds=${seasonIds ?? seasonId}` +   // backward compatibility with old embed links
    (teamId ? `&teamId=${teamId}` : '') +
    ``
  );

  return (
    <EmbedWrapper isLoading={isLoading} isError={isError} lang={lang}>
      <EmbedGamesInner data={data} isLoading={isLoading} lang={lang} showTeamFilter={teamId == null || teamId == ''} />
    </EmbedWrapper>
  )
}

export default EmbedGames