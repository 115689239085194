import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import useLoadEntity from 'hooks/useLoadEntity';
import useFetchData from 'hooks/useFetchData';
import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import { Button, Card, Divider, Input, Modal, Spin, Tabs, Tooltip } from 'antd';
import GameInfo from 'components/Game/GameInfo';
import GameLineup from 'components/Game/GameLineup';
import GamePayments from 'components/Game/GamePayments';
import GameNews from 'components/Game/GameNews';
import GameResults from 'components/Game/GameResults';
import { BeatLoader } from 'react-spinners';
import icons from 'utils/icons';
import GameAwards from 'components/Game/GameAwards';
import HmsTabbedTables from 'components/HmsTabbedTables';
import GameEvents from 'components/Game/GameEvents';
import openInNewTab from 'utils/openInNewTab';
import { useState } from 'react';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import moment from 'moment';
import { BsFillPersonFill } from 'react-icons/bs';
import TextArea from 'antd/es/input/TextArea';
import copy from 'copy-to-clipboard';
import GameInviteModal from 'components/Modals/GameInviteModal';
import EntityLogo from 'components/EntityLogo';
import EntityAttachments from 'components/EntityAttachments';
import GameOverwrite from 'components/Game/GameOverwrite';
import GameDisciplinary from 'components/Game/GameDisciplinary';
import TabsWithUrlMemory from 'components/TabsWithUrlMemory';
import { getDefaultCardWidth } from 'utils/uiPreferences';

const Game = () => {
  const entities = useEntities();
  const entity = entities.Game;
  const { id, isLoading, isError, isNotFound, data, relationsData, reload, reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'Awards',
      'Lineups',
      'Lineups>Player',
      'Lineups>ListPosition',
      'Phase>Group>Season>Competition',   // needed to show 'Open embed' button under System + for "New Disciplinary"
      'Venue',                // temporary!! only for invite -- TODO: do this better!!!
    ].join(','),
  });
  const { relationsData: disciplinaryData, reloadRelations: reloadDisciplinaries } = useLoadEntity({
    entity,
    relations: [
      'Disciplinaries,Disciplinaries>Game(startDate,startTime),Disciplinaries>Game>HomeTeam(short),Disciplinaries>Game>AwayTeam(short),Disciplinaries>Game>Venue(name),Disciplinaries>Players(firstName,lastName,logoUrl)',
    ].join(','),
  });
  const { data: gameResult, gameResultIsLoading, reload: gameResultReload } = useFetchData(`/admin/gameResult?id=${id}`);
  const { isLoading: gameEventsLoading, data: gameEventsData, reload: gameEventsReload } = useFetchEntity(entity.name, id,
    [
      'GameEventGoals',
      'GameEventGoals>ScoredByPlayer',
      'GameEventGoals>ScoredByTeam',
      'GameEventGoals>AssistedBy1Player',
      'GameEventGoals>AssistedBy2Player',
      'GameEventGoals>AllowedByPlayer',
      'GameEventSaves',
      'GameEventSaves>SavedByTeam',
      'GameEventSaves>SavedByPlayer',
      'GameEventPenalties',
      'GameEventPenalties>PenalizedPlayer',
      'GameEventPenalties>PenalizedTeam',
      'GameEventInjuries',
      'GameEventInjuries>InjuredPlayer',
      'GameEventInjuries>InjuredTeam',
      'GameEventFaceOffs',
      'GameEventFaceOffs>WonByPlayer',
      'GameEventFaceOffs>WonByTeam',
      'GameEventFaceOffs>LostByPlayer',
      'GameEventPenaltyshots',
      'GameEventPenaltyshots>ExecutedByPlayer',
      'GameEventPenaltyshots>ExecutedByTeam',
      'GameEventPenaltyshots>FacedAgainstPlayer',
    ].join(','),
    {
      separateIncludes: true
    }
  )
  const gameEvents = gameEventsLoading ? null :
    [
      ...(gameEventsData?.GameEventGoals ?? []).map(r => ({ entity: 'GameEventGoal', key: r.gameEventGoalId, ...r })),
      ...(gameEventsData?.GameEventSaves ?? []).map(r => ({ entity: 'GameEventSave', key: r.gameEventSaveId, ...r })),
      ...(gameEventsData?.GameEventPenalties ?? []).map(r => ({ entity: 'GameEventPenalty', key: r.gameEventPenaltyId, ...r })),
      ...(gameEventsData?.GameEventInjuries ?? []).map(r => ({ entity: 'GameEventInjury', key: r.gameEventInjuryId, ...r })),
      ...(gameEventsData?.GameEventFaceOffs ?? []).map(r => ({ entity: 'GameEventFaceOff', key: r.gameEventFaceOffId, ...r })),
      ...(gameEventsData?.GameEventPenaltyshots ?? []).map(r => ({ entity: 'GameEventPenaltyshot', key: r.gameEventPenaltyshotId, ...r })),
    ]
      .map(r => ({ type: r.entity, ...r }));
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({
    entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  const afterSave = () => {
    gameResultReload();
  }

  const competitionId = relationsData?.Phase?.Group?.Season?.Competition?.competitionId;
  const seasonId = relationsData?.Phase?.Group?.Season?.seasonId;

  const [inviteOpen, setInviteOpen] = useState(false);

  const showInvite = () => {
    setInviteOpen(true);
  }

  const hideInvide = () => {
    setInviteOpen(false);
  }

  const [values, setValues] = useState(null);

  const onDataChanged = (values) => {
    setValues(values);
  }

  return (
    <>
      <GameInviteModal open={inviteOpen} data={data} relationsData={relationsData} onClose={hideInvide} />

      <HmsEntityFormController
        {...{ entity, id, isLoading, isError, isNotFound, data, reload, afterSave, onDataChanged }}
        canClone={true}
        beforeSave={(data) => {
          delete data.status;       /* need to prevent status from being sent back to the server as it is not editabe and causes Game to switch back to RUNNING undeliberately */
          return data;
        }}
        buttons={[{
          label: 'Play Mode',
          icon: icons.Game,
          onClick: () => { openInNewTab(`/gameplay/${id}`) },
          disabled: id == '_new_' || data?.HomeTeam == null || data?.AwayTeam == null || data?.isCloneDraft,
          disabledTooltip: 'Need to save game first and select home and away team'
        }]}
        systemButtons={[
          {
            label: 'Show Invite',
            onClick: () => { showInvite() },
            disabled: !seasonId || data?.isCloneDraft,
            disabledTooltip: 'Need to save game first and select membership'
          },
          {
            type: 'divider'
          },
          {
            label: 'Open embed - games',
            onClick: () => { openInNewTab(`/embed/games?seasonIds=${seasonId}&lang=cs`) },
            disabled: !seasonId || data?.isCloneDraft,
            disabledTooltip: 'Need to save game first and select membership'
          },
          {
            label: 'Open embed - game',
            onClick: () => { openInNewTab(`/embed/game?gameId=${id}&lang=cs`) },
            disabled: !seasonId || data?.isCloneDraft,
            disabledTooltip: 'Need to save game first and select membership'
          },
          {
            label: 'Open embed - standings',
            onClick: () => { openInNewTab(`/embed/standings?competitionIds=${competitionId}&lang=cs`) },
            disabled: relationsData?.Phase == null || data?.isCloneDraft,
            disabledTooltip: 'Need to save game first and select membership'
          },
          {
            label: 'Open YT thumbnail',
            onClick: () => { openInNewTab(`/infographics?selectedTab=IG4&gameId=${id}`) },
            disabled: relationsData?.Phase == null || data?.isCloneDraft,
            disabledTooltip: 'Need to save game first and select membership'
          },
        ].map((b, idx) => {
          if (b.disabled && b.disabledTooltip) {
            b.label = <Tooltip className="inline-block w-full" title={b.disabledTooltip}>{b.label}</Tooltip>
          }
          delete b.disabledTooltip;
          return b;
        })}
        form={(
          <TabsWithUrlMemory
            className="no-tab-padding w-[calc(100vw-75px)] desktop:w-[calc(100vw-280px)]"
            items={[
              {
                label: `Basic`,
                children: <GameInfo data={data} formValues={values} />
              },
              {
                label: `Payments`,
                children: <GamePayments data={data} />,
                disabled: id == '_new_'
              },
              {
                label: `News`,
                children: <GameNews />,
                disabled: id == '_new_'
              },
              {
                label: `Overwrite`,
                children: <GameOverwrite />,
                disabled: id == '_new_'
              },
            ].map(tab => ({ ...tab, key: tab.label }))}
          />
        )}

        footer={id != '_new_' && (
          <div className="mt-4 min-h-[calc(100vh-120px)]">
            <div className="mt-4">
              <HmsTabbedTables
                className="no-tab-padding w-[calc(100vw-75px)] desktop:w-[calc(100vw-280px)]"
                // defaultActiveKey="Results"
                tables={[
                  {
                    label: `Line up`,
                    children: <GameLineup data={data} />,
                  },
                  {
                    label: `Game Results`,
                    children: <GameResults data={data} id={id} gameResult={gameResult} isLoading={gameResultIsLoading} />,
                  },
                  {
                    label: `Game Events`,
                    children: <GameEvents data={gameEvents} reload={gameEventsReload} lineups={relationsData?.Lineups} homeTeam={data?.HomeTeam} awayTeam={data?.AwayTeam} />,
                  },
                  {
                    label: "Awards", // + (relationsData?.Awards ? (' (' + relationsData?.Awards?.length + ')') : ''),
                    children: <GameAwards sourceEntityId={data?.gameId} data={relationsData?.Awards} onChange={reloadRelations} />,
                  },
                  {
                    label: "Disciplinaries" + (disciplinaryData?.Disciplinaries ? (' (' + disciplinaryData?.Disciplinaries?.length + ')') : ''),
                    children: <GameDisciplinary sourceEntityId={data?.gameId} competitionId={relationsData?.Phase?.Group?.Season?.Competition?.competitionId} data={disciplinaryData?.Disciplinaries} onChange={reloadDisciplinaries} />,
                  },
                  {
                    label: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
                ].map(tab => ({ title: tab.label, children: tab.children }))}
              />
            </div>
          </div>
        )}

        header={
          id != '_new_' &&
          <div className={`${data?.status == 'FINISHED' ? 'bg-[#1d4db6]' : 'bg-[#bbb]'} rounded-lg text-white p-3 mb-4 text-[0.9rem] text-center ${getDefaultCardWidth(true)}`}>
            <div className="flex items-center justify-center gap-4">
              <span className="w-[200px] text-right">{data?.HomeTeam?.nick}</span>
              <EntityLogo entity={data?.HomeTeam} size={24} className="rounded-[1rem] border-[1px] border-white border-solid" />
              {gameResult &&
                <span className="font-bold text-[1.3rem] flex items-center gap-1">
                  <span>{gameResult?.HomeTeam_GameEventGoal_Count}</span>
                  <span>:</span>
                  <span>{gameResult?.AwayTeam_GameEventGoal_Count}</span>
                </span>
              }
              {!gameResult &&
                <BeatLoader size="6px" color="white" className="mx-1" />
              }
              <EntityLogo entity={data?.AwayTeam} size={24} className="rounded-[1rem] border-[1px] border-white border-solid" />
              <span className="w-[200px] text-left">{data?.AwayTeam?.nick}</span>
            </div>
            {data?.overwriteEnabled &&
              <div className="text-orange-400 mt-3 flex gap-2 items-center justify-center text-sm">
                {icons.WarningTriangle}
                Overwrite Active
                (original result is {gameResult?.HomeTeam_GameEventGoal_Count_Original} : {gameResult?.AwayTeam_GameEventGoal_Count_Original})
              </div>
            }
          </div>
        }
      />
    </>
  );
}

export default Game
